import React from 'react'
import {Form,
  Dropdown,
  Checkbox,
  Message} from 'semantic-ui-react'

export default function UploadVodSendControls({
  vodUploadOptions,
  cloudPlaylists,
  cloudPlaylistsFetchError,
  setVodUploadOptions,
  fetchCloudPlaylists,
  clearCloudPlaylistFetchError,
}) {
  let {
    doSend,
    caption_file,
    add_to_playlists,
  } = vodUploadOptions

  React.useEffect(() => {
    fetchCloudPlaylists()
  }, [fetchCloudPlaylists])

  let playlistOptions = []
  if(cloudPlaylists) {
    playlistOptions = cloudPlaylists.map((playlist) => ({key: playlist._id, text: playlist.name, value: playlist._id}))
  }

  let playlistsDropdown = ""
  if(cloudPlaylistsFetchError) {
    playlistsDropdown = (
      <Message negative>
        <Message.Header>Error fetching cloud playlists:</Message.Header>
        {cloudPlaylistsFetchError}
      </Message>
    )
  } else if(playlistOptions.length) {
    playlistsDropdown = (
      <Form.Field disabled={!doSend}>
        <label>Add file(s) to cloud playlist(s):</label>
        <Dropdown fluid
          multiple
          selection
          search
          value={add_to_playlists || []}
          onChange={(e, data) => {setVodUploadOptions({...vodUploadOptions, add_to_playlists: data.value})}}
          options={playlistOptions}
        />
      </Form.Field>
    )
  } else {
    playlistsDropdown = (
      <Form.Field disabled={!doSend}>
        <label>Loading Cloud Playlists...</label>
        <Dropdown fluid
          disabled={true}
          multiple
          selection
          search
          options={[]}
        />
      </Form.Field>
    )
  }

  return (
    <>
      <Form.Field>
        <label>CCS</label>
        <Checkbox
          toggle
          label="Send to CASTUS Cloud"
          checked={doSend}
          onChange={(e, data) => {
            setVodUploadOptions({...vodUploadOptions, doSend: data.checked})
          }}
        />
      </Form.Field>
      <Form.Checkbox
        toggle
        label="Caption File"
        disabled={!doSend}
        checked={caption_file}
        onChange={(e, data) => {
          setVodUploadOptions({...vodUploadOptions, caption_file: data.checked})
        }}
      />
      {playlistsDropdown}
    </>
  )
}
