/**
 * Sets source based on SOURCE environment variable, or to the value in this config if it is not specified.
 * Setting SOURCE to local will fetch resources from local test files
 * Setting SOURCE to server will fetch resources from server as specified in helpers/net_helpers.js
 */
const SOURCE = 'server'

// URL of server to send requests to. If set to "", will send requests locally
const SERVER_URL = process.env.REACT_APP_SERVER_URL || ""

// Base url path of client
const URL_BASE = "/quickroll5"

// Current version number of V5 interface
const version = "5.8.0"

// Last Compile Date
const COMPILE_DATE = "Fri Sep 06 2024"

module.exports = {
  SOURCE,
  SERVER_URL,
  URL_BASE,
  version,
  COMPILE_DATE
}
