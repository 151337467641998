import React, { PureComponent } from 'react'
import { Header,
  Divider,
  Icon,
  Button,
  Dropdown,
  Table,
  Image} from 'semantic-ui-react'
import loadingIndicator from 'components/higher_order_components/Loader/LoadingIndicator'
import moment from 'moment'

import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import { actions } from 'redux/components/input_recording'
import { openApplication } from 'redux/menu'
import { messages } from 'redux/messages'

import { getIn, millisToHourString } from 'helpers/general_helpers'
import { parseFileSize } from 'helpers/library_helpers'

import SettingIdSelector from 'selectors/SettingIdSelector'

import './InputRecording.css';
// import 'semantic-ui-css/semantic.min.css';

import InputLiveControls from './InputLiveControls'
import InputRecordingModal from './InputRecordingModal'
import RecordingEventRow from './RecordingEventRow'

export class InputRecordingApp extends PureComponent {

    componentDidMount() {
      this.props.setCurrentInput(this.props.id)
      this.props.connectToRecordingSocket()
      this.props.fetchAssociations(this.props.id)
      this.props.fetchEvents()
      this.pollUpdate = setTimeout(this.poll, 10) /* Get the input going, now! */
    }

    poll = () => {
      this.props.pollRecordingStatus()
      this.pollUpdate = setTimeout(this.poll, 5000)
    }

        /* copied from V4 */
        filterCaptionStatus608 = (x) => {
                if (x === undefined) return { };

                let CC = [ undefined, undefined ];
                let TEXT = [ undefined, undefined, undefined, undefined ];
                let a = x.split('\n');
                let l,i;

                for (i=0;i < a.length;i++) {
                        l = a[i];
                        if (l.substr(0,10) === "Odd field:") {
                                if (CC[0] === undefined) CC[0] = l.substr(10).trim(); /* Odd field:...*/
                                if (CC[0] !== undefined) CC[0] = CC[0].replace(/ 8080/g,"").trim();
                                if (CC[0] === "Odd field:") CC[0] = '';
                        }
                        else if (l.substr(0,11) === "Even field:") {
                                if (CC[1] === undefined) CC[1] = l.substr(11).trim(); /* Even field:...*/
                                if (CC[1] !== undefined) CC[1] = CC[1].replace(/ 8080/g,"").trim();
                                if (CC[1] === "Even field:") CC[1] = '';
                        }
                        else if (l.substr(0,4).match(/^CC\d:/)) {
                                let srv = parseInt(l.substr(2,1)) - 1;
                                if (srv >= 0 && srv <= 3) {
                                        if (TEXT[srv] === undefined) TEXT[srv] = l.substr(4).trim(); /* CC1:... */
                                        /* strip quotation marks */
                                        if (TEXT[srv] !== undefined) {
                                                if (TEXT[srv].substr(0,1) === '"' && TEXT[srv].substr(TEXT[srv].length-1,1) === '"') {
                                                        TEXT[srv] = TEXT[srv].substr(1,TEXT[srv].length-2);
                                                        TEXT[srv] = TEXT[srv].trim();
                                                        if (TEXT[srv].length > 60) TEXT[srv] = TEXT[srv].substr(TEXT[srv].length-60);
                                                }
                                        }
                                }
                        }
                }

                return { cc: CC, text: TEXT };
        }

        /* copied from V4 */
        filterCaptionStatus708 = (x) => {
                if (x === undefined) return { };

                let Service = [ undefined, undefined, undefined, undefined, undefined, undefined ];
                let RAW = undefined;

                let a = x.split('\n');
                let l,i;

                for (i=0;i < a.length;i++) {
                        l = a[i];
                        if (l.substr(0,4) === "RAW:") {
                                if (RAW === undefined) RAW = l.trim();
                                if (RAW === "RAW:") RAW = '';
                        }
                        else if (l.substr(0,10).match(/^Service \d:/)) {
                                let srv = parseInt(l.substr(8,1)) - 1;
                                if (srv >= 0 && srv <= 5) {
                                        if (Service[srv] === undefined) Service[srv] = l.substr(10).trim();
                                        if (Service[srv] !== undefined) {
                                                if (Service[srv].substr(0,1) === '"' && Service[srv].substr(Service[srv].length-1,1) === '"') {
                                                        Service[srv] = Service[srv].substr(1,Service[srv].length-2);
                                                        Service[srv] = Service[srv].trim();
                                                        if (Service[srv].length > 60) Service[srv] = Service[srv].substr(Service[srv].length-60);
                                                }
                                        }
                                }
                        }
                }

                return { service: Service, raw: RAW };
        }

        /* copied from V4 */
        filterCaptionStatusVTT = (x) => {
                if (x === undefined) return { };

                let Service = [ ];
                let Languages = [ ];

                let a = x.split('\n');
                let l,i;

                for (i=0;i < a.length;i++) {
                        l = a[i];
                        if (l.match(/^VTT\d+:/)) {
                                let ci = l.indexOf(':')
                                if (ci >= 4) {
                                        let srv = parseInt(l.substr(3,ci-3)) - 1;
                                        if (srv >= 0 && srv <= 256) {
                                                while (Service.length <= srv) Service.push(undefined);
                                                while (Languages.length <= srv) Languages.push(undefined);
                                                if (Service[srv] === undefined) Service[srv] = l.substr(ci+1).trim();
                                                if (Service[srv] !== undefined) {
                                                        /* VTT1: 107.587-110.337 (now=2.024) "seldom told us from for months." lang=English */
                                                        let qi = Service[srv].indexOf('"')
                                                        if (qi >= 0) {
                                                                let os = Service[srv];
                                                                Service[srv] = Service[srv].substr(qi+1);
                                                                let q2 = Service[srv].indexOf('"')
                                                                if (q2 >= 0) {
                                                                        Service[srv] = Service[srv].substr(0,q2);
                                                                        os = os.substr(0,qi)+" "+os.substr(qi+1+1+q2)

                                                                        os = os.split(/ +/)
                                                                        if (os) {
                                                                                let oi,oe,oei,on,ov;
                                                                                for (oi=0;oi < os.length;oi++) {
                                                                                        oe = os[oi];
                                                                                        oei = oe.indexOf('=');
                                                                                        if (oei >= 0) {
                                                                                                on = oe.substr(0,oei);
                                                                                                ov = oe.substr(oei+1);
                                                                                                if (on === "lang") {
                                                                                                        Languages[srv] = ov;
                                                                                                }
                                                                                        }
                                                                                }
                                                                        }
                                                                }
                                                                else {
                                                                        Service[srv] = '';
                                                                }
                                                        }
                                                        else {
                                                                Service[srv] = '';
                                                        }
                                                }
                                        }
                                }
                        }
                }

                return { service: Service, language: Languages };
        }

        /* copied from V4 */
        filterCaptionStatusLiveCaptionText = (x) => {
                if (x === undefined) return { };

                let Service = [ ];
                let Languages = [ ];

                let a = x.split('\n');
                let l,i,ii,name,value;

                for (i=0;i < a.length;i++) {
                        l = a[i];
                        /* srv=2,lang=Spanish:Blah blah blah */
                        let ci = l.indexOf(':');
                        if (ci < 0) continue;
                        let nv = l.substr(0,ci);
                        let st = l.substr(ci+1);

                        let lang = '';
                        let srv = -1;

                        nv = nv.split(',');
                        for (ii=0;ii < nv.length;ii++) {
                                ci = nv[ii].indexOf('=');
                                if (ci < 0) continue;
                                name = nv[ii].substr(0,ci);
                                value = nv[ii].substr(ci+1);

                                if (name === "srv") {
                                        srv = parseInt(value) - 1;
                                }
                                else if (name === "lang") {
                                        lang = value;
                                }
                        }

                        if (srv >= 0 && srv < 256) {
                                while (Service.length <= srv) Service.push(undefined);
                                while (Languages.length <= srv) Languages.push(undefined);
                                Service[srv] = st;
                                Languages[srv] = lang;
                        }
                }

                return { service: Service, language: Languages };
        }

        /* copied from V4 */
        updateCaptionStatus = (propSet,currentStatus) => {
                let liveRunning = false;
                let htmls = [ ];
                let html = [ ];
                let ci,obj;

                if (currentStatus)
                        liveRunning = currentStatus.liveCaptionIsRunning;

		if (typeof(propSet) === 'object') {
			let statliv = undefined;
			let statvtt = undefined;
			let stat708 = undefined;
			let stat608 = undefined;

			obj = propSet['live caption text status']; /* this is a special status made specificially for this UI */
			if (obj !== undefined && obj !== '' && liveRunning)
				statliv = this.filterCaptionStatusLiveCaptionText(obj);

			obj = propSet['webvtt status'];
			if (obj !== undefined && obj !== '')
				statvtt = this.filterCaptionStatusVTT(obj);

			obj = propSet['cea-708_caption_status']; // *sigh* way to be consistent with the 608 status
			if (obj !== undefined && obj !== '')
				stat708 = this.filterCaptionStatus708(obj);

			obj = propSet['eia_608_caption_status']; // *sigh* way to be consistent with the 708 status
			if (obj !== undefined && obj !== '')
				stat608 = this.filterCaptionStatus608(obj);

			for (ci=0;ci < 16;ci++) {
				let ok = false;

				if (!ok) {
					if (statliv) {
						if (statliv.service && ci < statliv.service.length && statliv.service[ci] && statliv.service[ci].length > 0) {
							let more = '';
							if (statliv.language && ci < statliv.language.length && statliv.language[ci] && statliv.language[ci].length > 0) {
								more += " ("+statliv.language[ci]+")";
							}

							htmls.push((<span style={{fontSize: '7pt', fontWeight: '300'}}>Service {ci+1}: "{statliv.service[ci]}"{more}</span>));
							ok = true;
						}
					}
				}

				if (!ok) {
					if (statvtt) {
						if (statvtt.service && ci < statvtt.service.length && statvtt.service[ci] && statvtt.service[ci].length > 0) {
							let more = '';
							if (statvtt.language && ci < statvtt.language.length && statvtt.language[ci] && statvtt.language[ci].length > 0) {
								more += " ("+statvtt.language[ci]+")";
							}

							htmls.push((<span style={{fontSize: '7pt', fontWeight: '300'}}>VTT service {ci+1}: "{statvtt.service[ci]}"{more}</span>));
							ok = true;
						}
					}
				}

				if (!ok) {
					if (stat708) {
						if (stat708.service && stat708.service[ci] && stat708.service[ci].length > 0) {
							htmls.push((<span style={{fontSize: '7pt', fontWeight: '300'}}>708 service {ci+1}: "{stat708.service[ci]}"</span>));
							ok = true;
						}
					}
				}

				if (!ok) {
					if (stat608) {
						if (stat608.text && stat608.text[ci] && stat608.text[ci].length > 0) {
							htmls.push((<span style={{fontSize: '7pt', fontWeight: '300'}}>608 CC{ci+1}: "{stat608.text[ci]}"</span>));
							ok = true;
						}
					}
				}
			}
		}

                for (ci=0;ci < htmls.length;ci++) {
                        html.push((<div key={`capstat${ci}`}>{htmls[ci]}</div>));
                }

		return html;
        }

    componentWillUnmount() {
      clearTimeout(this.pollUpdate)
      this.props.setCurrentInput('')
    }

    componentDidUpdate(oldProps) {
      if(oldProps.id !== this.props.id) {
        this.props.setCurrentInput(this.props.id)
        this.props.connectToRecordingSocket()
        this.props.fetchAssociations(this.props.id)
        this.props.fetchEvents()
      }
    }

    handleRenameRecording = () => {
      let { input_recording, ...actions } = this.props
      actions.prompt("What do you want to call this recording?", (val) => {
        if(val === null) {
          return
        }
        actions.renameRecording(val)
      })
    }

    handleGoLive = (chan) => {
      this.props.confirm(`Click OK when you are ready to go live on Channel ${chan}.`, (val) => {
        if(val) {
          this.props.goLive(chan)
        }
      }, {confirmText: 'OK', cancelText: 'Cancel'})
    }

    handleEndLive = (chan) => {
      this.props.confirm(`Click OK when you are ready to end live on Channel ${chan}.`, (val) => {
        if(val) {
          this.props.endLive(chan)
        }
      }, {confirmText: 'OK', cancelText: 'Cancel'})
    }

    formatTimeValue = (data) => {
      if(typeof data === 'string') {
        let formats = ['h:mm:ss a', 'h:mm:ssa', 'h:mm a', 'h:mma']
        let momVal = moment(data, formats, true)
        if(momVal.isValid()) {
          data = momVal
        }
      }
      return data
    }

    formatDateValue = (data) => {
      if(typeof data === 'string') {
        let formats = ['M/D/YYYY']
        let momVal = moment(data, formats, true)
        if(momVal.isValid()) {
          data = momVal
        }
      }
      return data
    }

    handleAssociationSelect = (e, data) => {
      this.props.selectAssociation(data.value)
    }

    handleApplyAssociation = () => {
      if(this.props.input_recording.selectedAssociation) {
        this.props.applyAssociation()
      }
    }

    handleDeleteEvent = (recordingEvent) => {
      this.props.confirm(`Are you sure you want to delete the event ${recordingEvent["recording name"]} from input ${recordingEvent.input}?`, (val) => {
        if(val) {
          this.props.deleteEvent(recordingEvent.id)
        }
      }, {confirmText: "Delete", cancelText: "Cancel"})
    }

    render() {
        let options = [];

        const { input_recording, channels, inputs, timezone, ...actions } = this.props

        let { currentInput, recordingStatus, recordingPreview, associations, events, selectedAssociation } = input_recording

        let thisInput = inputs[currentInput] || {}
        let thisInputSettings = thisInput.settings || {}

        events = events.filter((evnt) => evnt.input === currentInput)

        let listItems;

        if (Object.keys(events).length === 1 && events[0].id === 999999999 && events[0].errorMessage === true) {
          listItems = <Table.Row><Table.Cell style={{paddingLeft: "1em", color: "#AF0000"}} colSpan="4">{events[0]['recording name']}</Table.Cell></Table.Row>
        }
        else {
          listItems = events.map((event, index) =>
            <RecordingEventRow recordingEvent={event}
              onDelete={this.handleDeleteEvent}
              onChange={actions.editEvent}
              timezone={timezone}
              key={event.id}/>
          );
        }

        let recordingLabel = '(Unknown)'
        let recordingName = '(Untitled)'
        let recordButton = ''
        let recordTime = ''
        let recordSize = ''
        let previewContainerCls = 'blackArea'
        let liveCaptionButton = ''
        let liveCaptionStatusLabel = ''
        if(recordingStatus) {
          if(getIn(recordingStatus, [currentInput, 'recording']) === '1') {
            recordingLabel = getIn(recordingStatus, [currentInput, 'active recording file']) || "(Recording)"
            recordTime = getIn(recordingStatus, [currentInput, 'active recording time']) || "0"
            recordTime = millisToHourString(parseFloat(recordTime) * 1000)
            recordSize = getIn(recordingStatus, [currentInput, 'active recording file size']) || "0"
            recordSize = parseFileSize(recordSize)
            recordButton = <Button icon='stop' onClick={actions.stopRecording}/>
            if(recordingLabel === "(waiting)") {
             previewContainerCls = 'blackArea waiting'
            } else {
             previewContainerCls = 'blackArea recording'
            }
          } else {
            recordingLabel = "(Not Recording)"
            recordButton = <Button icon={<Icon name='circle' color='red'/>} onClick={actions.startRecording}/>
          }
          recordingName = getIn(recordingStatus, [currentInput, 'recording name']) || '(Untitled)'
        }

        let liveCaption = getIn(recordingStatus, [currentInput, 'liveCaption']) || { }
        let liveCapBtnComStyle = { paddingLeft: "0px", paddingRight: "0px", textAlign: "center", width: "38px", height: "36px", fontWeight: 900, fontSize: "10px" }

        let caption = this.updateCaptionStatus(thisInputSettings,liveCaption)

        if (!liveCaption.liveCaptionConfigured) {
                liveCaptionButton = <Button disabled={true} content="(CC)" style={liveCapBtnComStyle} />
                liveCaptionStatusLabel = ''
        }
        else if (!liveCaption.liveCaptionEnabled) {
                liveCaptionButton = <Button disabled={true} content="(CC)" style={liveCapBtnComStyle} />
                liveCaptionStatusLabel = 'Not enabled in settings'
        }
        else {
                let onclick;

                if (liveCaption.liveCaptionButtonStart)
                        onclick = actions.startLiveCaptions;
                else
                        onclick = actions.stopLiveCaptions;

                if (liveCaption.liveCaptionIsRunning) {
                        liveCapBtnComStyle = {
                                textShadow: '0px 0px 2px #5F0000',
                                color: '#FF0000',
                                ...liveCapBtnComStyle
                        }
                }

                let more = ''

                if (liveCaption.liveCaptionIsRunning) {
                        if (liveCaption.liveCaptionRuntime > 0) {
                                var t = Math.floor(liveCaption.liveCaptionRuntime + 0.5);
                                var s = t % 60; t = Math.floor(t / 60);
                                var m = t % 60; t = Math.floor(t / 60);
                                var h = t;

                                s = s.toString();
                                if (s.length < 2) s = '0' + s;

                                m = m.toString();
                                if (m.length < 2) m = '0' + m;

                                more += ' [' + h + ':' + m + ':' + s + ']';
                        }
                }

                liveCaptionButton = <Button content="(CC)" style={liveCapBtnComStyle} onClick={onclick} />
                if (liveCaption.liveCaptionRunningUFIsErrors) {
                        liveCaptionStatusLabel = <span><span style={{color: "#FF1F1F"}}>{liveCaption.liveCaptionRunningUF}</span>{more}</span>
                }
                else {
                        liveCaptionStatusLabel = (liveCaption.liveCaptionRunningUF || liveCaption.liveCaptionRunning) + more
                }
        }

        if(associations && currentInput in associations) {
          options = associations[currentInput].map((assoc) => ({
            text: assoc.name,
            value: assoc.association
          }))
        }

        let recordTimeAndSize = ''

        if (recordTime !== undefined && recordTime !== "") {
                if (recordTimeAndSize !== "") recordTimeAndSize += " / "
                recordTimeAndSize += recordTime
        }

        if (recordSize !== undefined && recordSize !== "") {
                if (recordTimeAndSize !== "") recordTimeAndSize += " / "
                recordTimeAndSize += recordSize
        }

        return (
            <div className="inputRecording">
                <div className="inputRecording__MainContainer">
                    <Header as='h2' icon='record' content={currentInput} id='RecordingControlsHeader'/>
                    <Divider fitted/>
                    <div className="inputRecording__Row noselect">
                        <div className="inputRecording--div">
                            <div className={previewContainerCls}>
                              <Image src={getIn(recordingPreview, [currentInput, 'current'])} id='recordingPreview' alt=''/>
                            </div>
                        </div>
                        <div className="inputRecording--div noselect">
                            <div className="inputRecording--buttonMenu">
                              <Button.Group>
                                <Button icon='arrow left' content='Back' onClick={() => actions.openApplication('Settings', '/input')}/>
                                <Button icon='settings' content='Settings' onClick={() => actions.openApplication('Settings', `/input/${currentInput}`)}/>
                              </Button.Group>
                            </div>
                            <div>
                                <Button.Group style={{marginRight: 10}}>
                                  {recordButton}
                                </Button.Group>
                                {recordingLabel}
                            </div>
                            <div>
                                <Button.Group style={{marginRight: 10}}>
                                  {liveCaptionButton}
                                </Button.Group>
                                {liveCaptionStatusLabel}
                            </div>
                            <div>
                              {recordTimeAndSize}
                            </div>
                            <div className="inputRecording--buttonMargin5">
                                <Button icon labelPosition='left' style={{marginRight: 10}} onClick={this.handleRenameRecording}>
                                    <Icon name='edit' />
                                    Rename
                                </Button>
                                {recordingName}
                            </div>
                            <div className="inputRecording--buttonMargin5">
                                <InputLiveControls channels={channels}
                                  currentInput={currentInput}
                                  goLive={this.handleGoLive}
                                  endLive={this.handleEndLive}/>
                            </div>
                            <div style={{fontName: "Liberation Sans, sans-serif", fontSize: 10, lineHeight: '1.3em' }}>
                                {(caption !== "") ? <div>{caption}</div> : <div/>}
                            </div>
                            {options.length ? <div className="inputRecording--buttonMargin">
                                <Dropdown placeholder='Select' selection options={options} style={{width: '50%'}} value={selectedAssociation} onChange={this.handleAssociationSelect}/>
                                <Button content='Apply Route' style={{marginLeft: 10}} onClick={this.handleApplyAssociation}/>
                            </div> : ''}
                        </div>
                    </div>
                    <div className="inputRecording__listArea noselect">
                        Timer record events (click on a value to edit it):
                        <Button icon labelPosition='right' onClick={actions.eventModalOpen} style={{marginLeft: 10}}>
                            <Icon name='edit' />
                            Add event
                        </Button>
                        <div className="inputRecording__table">
                            <Table celled padded>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell singleLine>Name</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Repeat</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Start Date</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Finish Date</Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {listItems}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
                <InputRecordingModal input_recording={input_recording} channels={channels} actions={actions}/>
            </div>
        );
    }
}

let mapStateToProps = (state, ownProps) => ({
    input_recording: state.input_recording,
    channels: state.settings.services.channel,
    inputs: state.settings.services.input,
    id: SettingIdSelector(ownProps),
    timezone: state.menu.timezone,
    state,
    _loader: state.input_recording._loader,
    _loaderID: state.input_recording._loaderID
})

let mapDispatchToProps = (dispatch) => bindActionCreators({...actions, ...messages, openApplication}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(loadingIndicator(InputRecordingApp))
