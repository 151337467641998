import React, {PureComponent} from 'react'
import {List, Button, Icon, Label} from 'semantic-ui-react'
import {getIn} from 'helpers/general_helpers'

import './InputLiveControls.css'

const BASE_INPUT_PATH = '/mnt/main/tv/inputs/'

export default class InputLiveControls extends PureComponent {

  render() {
    let {currentInput, channels, goLive, endLive} = this.props

    let channelItems = Object.entries(channels).sort((a,b) => a[0].localeCompare(b[0])).map(([key, chan]) => {
      let num = /\d+/.exec(key)
      if(num) {
        num = num[0]
      }
      let name = getIn(chan, ['settings', 'name'])
      let chanQueue = getIn(chan, ['settings', 'queue', 'queue'])
      let leafPath = getIn(chan, ['settings', 'regions', 'item', 'media', 'leafPath'])
      if(leafPath) {
        leafPath = leafPath.join("/")
      }
      let isPlayNow = (chanQueue && chanQueue[0] === `${BASE_INPUT_PATH}${currentInput}`)
      let isPlayScheduled = (leafPath && leafPath === `${BASE_INPUT_PATH}${currentInput}`)
      let isLive = isPlayNow || isPlayScheduled
      let liveLabelStyle = {marginLeft:'0.0em', padding:'0.1em 0.22em', fontSize:'0.8rem'}
      let playNowStyle = {padding:'0.35em', fontSize:'0.7rem', margin: '0'}
      return (
        <List.Item key={key}>
          <List.Content style={{fontSize:'0.75em'}}>
            {isLive ?
              <Label color='green' content={isPlayNow?'LIVE':'SCHEDULED LIVE'} horizontal style={liveLabelStyle}/> : /* NTS: isPlayNow and isPlayScheduled can both be true! */
              <Label color='red' content='NOT LIVE' horizontal style={liveLabelStyle}/>}
            {name ? name : key}
            {isPlayNow ?
              <Button icon={<Icon name='stop' color='red'/>} onClick={() => endLive(num)} style={playNowStyle} size='mini' floated='right'/> :
              <Button icon={<Icon name='play' color='green'/>} onClick={() => goLive(num)} style={playNowStyle} size='mini' floated='right'/>}
          </List.Content>
        </List.Item>
      )
    })

    return (
      <List divided id="InputLiveControls">
        {channelItems}
      </List>
    )
  }

}
