import React, {Component} from 'react'
import LibraryFileRow from './LibraryFileRow'
import LibraryFileTile from './LibraryFileTile'
import {getIn, millisToHourString, objectCompare} from 'helpers/general_helpers'
import {parseFileSize, parseMtime, fileTypeInfo, specialFolderIcons, getContentWindowStatus} from 'helpers/library_helpers'

/* transplant from V4 */
function parseAssumeFloat(a) {
  a = parseFloat(a);
  if (Number.isNaN(a)) return 0; /* parseFloat("") == NaN */
  return a;
}

const filesystemTranslateHack = (v) => {
    if (v === "cdrom" || v === "network") v = "filesystem"
    return v
}

class LibraryFile extends Component {

  constructor(props) {
    super(props)
    this.item = React.createRef()
    this.state = {closerToBottom: false, temporarySelect: false, dropdownHeight: null}
  }

  shouldComponentUpdate(nextProps, nextState) {
    let {active, data, highlight} = this.props
    let {closerToBottom, dropdownHeight, temporarySelect} = this.state
    if(nextProps.active && nextProps.selectedChannel !== this.props.selectedChannel) {
      return true
    }
    return (active !== nextProps.active ||
      highlight !== nextProps.highlight ||
      !objectCompare(data, nextProps.data) ||
      closerToBottom !== nextState.closerToBottom ||
      dropdownHeight !== nextState.dropdownHeight ||
      temporarySelect !== nextState.temporarySelect)
  }

  componentDidUpdate(prevProps) {
    if(this.item.current &&
      this.props.highlight &&
      !prevProps.highlight) {
      this.item.current.scrollIntoView({block: "center"})
    }
  }

  updateCloserToBottom = () => {
    if(this.item.current) {
      let {y} = this.item.current.getBoundingClientRect()
      y = y - this.props.yAdjustment
      let closerToBottom = false
      let containerHeight = document.body.clientHeight - this.props.yAdjustment
      let dropdownHeight = Math.floor(containerHeight / 2.2)
      if(y > 0) {
        closerToBottom = (containerHeight / y) < 2
      }
      if(this.state.closerToBottom !== closerToBottom ||
        this.state.dropdownHeight !== dropdownHeight) {
        this.setState((state) => ({...state, closerToBottom, dropdownHeight}))
      }
    }
  }

  // Handler for navigating to a directory when double clicking
  handleDblClick = () => {
    let {changeLibraryPath, previewFile, handleOpenFile, path, data, noOpen} = this.props
    if(data.type === 'folder') {
      changeLibraryPath(path)
    } else if (data.type === 'filesystem/mounted' ||
      data.type === 'cdrom/mounted' ||
      data.type === 'network/mounted' ||
      data.type === 'filesystem/main') {
      let destination;
      if(data.type === 'filesystem/mounted' && data.data.place.trim()) {
        destination = data.data.place.toLowerCase()
      } else if(data.type === 'filesystem/main') {
        destination = 'main'
      } else {
        destination = data.data.dev || data.data.device
      }
      changeLibraryPath(['mnt', destination])
    } else if (data.type === 'filesystem/boot') {
      /* ignore */
    } else {
      let fileInfo = fileTypeInfo(getIn(data, ['metadata', 'file type']))
      let {type} = fileInfo
      if(previewFile && (type === 'video' || type === 'image' || type === 'audio')) {
        previewFile(path, type)
      } else if (!noOpen && handleOpenFile) {
        handleOpenFile(path, fileInfo)
      }
    }
  }

  // Handler for selecting on click
  handleClick = (e) => {
    let {displayType,
      path,
      selectFile,
      selectFileOnly,
      selectFileRange,
      ismntmaintv} = this.props
    if(ismntmaintv) {
      /* do nothing */
    } else if(displayType === "tile" && e.shiftKey) {
      selectFileRange(path)
    } else if(displayType === "tile" && e.ctrlKey) {
      selectFile(path)
    } else {
      selectFileOnly(path)
    }
  }

  // Handler for clicking on select box
  handleSelectBoxClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if(e.shiftKey) {
      this.props.selectFileRange(this.props.path)
    } else {
      this.props.selectFile(this.props.path)
    }
  }

  handleDropdownClick = (e) => {
    if(e) {
      e.stopPropagation()
    }
    if(!this.props.active) {
      this.props.selectFile(this.props.path)
      this.setState({temporarySelect: true})
    }
    this.updateCloserToBottom()
  }

  handleDropdownDeselect = () => {
    if(this.state.temporarySelect && this.props.active) {
      this.setState({temporarySelect: false})
      this.props.selectFile(this.props.path)
    }
  }

  handleAssociationChange = (e, data) => {
    this.props.handleSetInputAssociation(this.props.path, data.value)
  }

  testActionValue = (expected, received) => {
    if(expected instanceof Array) {
      return expected.includes(received)
    } else {
      return expected === received
    }
  }

  getDropdownActions = (fileData) => {
    let {actionDropdown, selectedFiles, ismntmaintv} = this.props
    return actionDropdown.filter((opt) => {
      if(opt.conditions) {
        for(let [test, value] of Object.entries(opt.conditions)) {
          switch(test) {
            case 'type':
              if(!this.testActionValue(value, filesystemTranslateHack(fileData.type))) {
                return false
              }
              break;
            case 'typeRaw':
              if(!this.testActionValue(value, fileData.type)) {
                return false
              }
              break;
            case 'subtype':
              if(!this.testActionValue(value, fileData.subtype)) {
                return false
              }
              break;
            case 'typeNot':
              if(this.testActionValue(value, filesystemTranslateHack(fileData.type))) {
                return false
              }
              break;
            case 'typeRawNot':
              if(this.testActionValue(value, fileData.type)) {
                return false
              }
              break;
            case 'subtypeNot':
              if(this.testActionValue(value, fileData.subtype)) {
                return false
              }
              break;
            case 'hasConsentForm':
              if(!this.testActionValue(value, fileData.hasConsentForm)) {
                return false
              }
              break;
            case 'driveNotMain':
              // Check for not allowing unmounting of main and boot drives
              // Check that dev is not sda5
              if(this.props.data.data.dev === "sda5" ||
              // Check that the drive's "place" value is not "main" or "boot" (case insensitive)
                (this.props.data.data.place && (
                  this.props.data.data.place.toLowerCase() === "main" ||
                  this.props.data.data.place.toLowerCase() === "boot")) ||
              // Check that "label" is not "data"
                (this.props.data.data.label && this.props.data.data.label.toLowerCase() === "data")) {
                return false
              }
              break;
            case 'notTv':
              if (ismntmaintv) return false
              break;
            default:
              break;
          }
        }
      }
      return true;
    }).map((opt) => {
      let targetArgs = []
      let target = opt.args ? opt.args : [["path"]]
      if(opt.allSelected) {
        targetArgs = [selectedFiles]
      } else {
        targetArgs = target.map((argPath) => getIn(this.props, argPath))
      }
      return {key: opt.key, text: opt.display, icon: opt.icon, onClick: () => opt.action(...targetArgs), dropdownParent: opt.dropdownParent}
    })
  }

  render() {
    let {displayType,
      path,
      filename,
      data,
      active,
      compact,
      highlight,
      ismntmain,
      ismntmaintv,
      doNotModify,
      inputAssociations,
      selectOne,
      selectedFiles,
      selectedChannel} = this.props
    let {closerToBottom, dropdownHeight} = this.state

    let DisplayItem;
    switch(displayType) {
      case 'row':
        DisplayItem = LibraryFileRow;
        break;
      case 'tile':
        DisplayItem = LibraryFileTile;
        break;
      default:
        throw new Error(`Unknown Display Type ${displayType} for library items. Display type must be either "row" or "tile"`);
    }

    let hasData = !!data;

    if(!hasData) {
      return <DisplayItem hasData={false}/>
    }

    let title = path.join('/')
    if(!title.startsWith('/')) {
      title = `/${title}`
    }
    let displayName = data.label || filename
    let cc = getIn(data, ['metadata', 'cc'])
    let hd = getIn(data, ['metadata', 'hd'])
    if (hd === undefined || hd === '') hd = getIn(data, ['metadata', 'sd'])
    let date = data.mtime ? parseMtime(data.mtime) : ""
    let size = data.size ? parseFileSize(data.size) : ""
    let filetype = getIn(data, ['metadata', 'file type']) || data.type
    let {type, subtype, display, icon} = fileTypeInfo(filetype)
    if(type === "folder") {
      size = ""
    }
    let associations = data.associations
    let selectedAssociation = data.selectedAssociation

    let durationValue = getIn(data, ['metadata', 'duration']) || ''

    // 2023/09/10: Hey, filetype can be undefined! Check first!!
    if (filetype !== undefined) {
      if (filetype.substr(0,11) === "filesystem/" || filetype.substr(0,6) === "cdrom/") {
        if (data.filesystem && data.filesystem !== "" && data.filesystem !== " ") {
          display = data.filesystem + " " + display
        }

        if (data.guid) // dev is placed here
          date = "/dev/" + data.guid
      }
    }

    let duration = durationValue || ''
    // special case: endless schedules should not have a duration. They are, by definition, endless
    if(type === "schedule" && subtype === "endless") {
      duration = ''
    }
    let outPoint = getIn(data, ['metadata', 'out'])
    let inPoint = getIn(data, ['metadata', 'in'])
    if(duration) { /* NTS: This only works because '' == false, which is apparently a feature of ECMAScript/JavaScript */
      if(typeof duration === 'string') {
        duration = parseAssumeFloat(duration)
      }
      let originalDuration
      if(inPoint || outPoint) {
        originalDuration = duration
      }
      if(outPoint) {
        duration = parseAssumeFloat(outPoint)
      }
      if(inPoint) {
        duration = duration - parseAssumeFloat(inPoint)
      }
      if(duration>0.0) {
        duration = millisToHourString(Math.floor(duration * 1000))
      } else {
        duration = '';
      }
      if(originalDuration && originalDuration > 0) {
        if(compact) {
          duration = duration + " (trim)"
        } else {
          duration = duration + ` (orig. ${millisToHourString(Math.floor(originalDuration * 1000))})`
        }
      }
    }

    let actions = this.getDropdownActions({...data, type, subtype});

    // Specific exceptions for special directories under /mnt/main
    if(type === "folder") {
      icon = specialFolderIcons(path, icon)
    }

    // Special handler for VTT caption files.
    // VTT caption files do not have a specific castus filetype metadata identifier, and must instead be identified by extension.
    if((type === "file" || type === "text") && path[path.length - 1].endsWith(".vtt")) {
      display = "VTT Caption File"
      icon = "closed_captions"
    }
    if((type === "file" || type === "text") && path[path.length - 1].endsWith(".srt")) {
      display = "SubRip Caption File"
      icon = "closed_captions"
    }

    let contentWindowData = getContentWindowStatus(data.metadata)

    return (
      <DisplayItem hasData={true}
        cc={cc}
        hd={hd}
        path={path}
        title={title}
        filename={displayName}
        date={date}
        size={size}
        generatingMetadata={data.generatingMetadata}
        captions={data.captions}
        filetype={display}
        icon={icon}
        duration={duration}
        highlight={highlight}
        ismntmain={ismntmain}
        ismntmaintv={ismntmaintv}
        doNotModify={doNotModify}
        durationValue={durationValue}
        selectedFiles={selectedFiles}
        compact={compact}
        active={active}
        selectOne={selectOne}
        associations={associations}
        selectedAssociation={selectedAssociation}
        dropdownActions={actions}
        closerToBottom={closerToBottom}
        dropdownHeight={dropdownHeight}
        contentWindowData={contentWindowData}
        spanRef={this.item}
        onClick={this.handleClick}
        onDblClick={this.handleDblClick}
        onSelectBoxClick={this.handleSelectBoxClick}
        inputAssociations={inputAssociations}
        onAssociationDropdownChange={this.handleAssociationChange}
        onAssociationDropdownOpenClose={this.updateCloserToBottom}
        onDropdownSelect={this.handleDropdownClick}
        onDropdownDeselect={this.handleDropdownDeselect}
        selectedChannel={selectedChannel}/>
    )

  }

}

export default LibraryFile;
