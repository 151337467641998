import React, {Component} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeLibraryPath,
  selectFile,
  selectFileOnly,
  setSelectedFiles,
  searchLibrary,
  sortLibrary,
  previewFile,
  viewConsentForm} from 'redux/components/library'
import LibrarySelector from 'selectors/LibrarySelector'
import {dataPath} from 'helpers/library_helpers'
import {getIn} from 'helpers/general_helpers'

import LibraryFileTable from './LibraryFileTable'
import LibraryFileTiles from './LibraryFileTiles'
import LibraryNavBar from './LibraryNavBar'
import LibrarySearchBar from './LibrarySearchBar'

import {Segment} from 'semantic-ui-react'

import FilePreviewModal from './FilePreviewModal'
import ConsentFormModal from './ConsentFormModal'

import loadingIndicator from 'components/higher_order_components/Loader/LoadingIndicator'

import './LibraryFrame.css'

class LibraryFrame extends Component {

  constructor(props) {
    super(props)
    this.selectFileRange = this.selectFileRange.bind(this)
    if(props.overridePath) {
      this.changePath(props.overridePath)
    } else {
      props.loadFileData(props.navpath)
    }
  }

  /**
   * Changes which filepath the library is viewing
   * @param {[String]} path A path to view in array form split on forward slashes (i.e. /mnt/main/tv would be ["", "mnt", "main", "tv"]
   */
  changePath = async (path) => {
    try {
      path = path.map(pathComponent => decodeURIComponent(pathComponent))
      await this.props.loadFileData(path, {forceLoading: true, throwNotExist: true})
    } catch (err) {
      if(err.code === "ENOENT" && !this.props.notExistOk) {
        try {
          let upperPath = path.slice(0, -1)
          if(upperPath.length > 1 || upperPath[0] === "mnt") {
            await this.props.loadFileData(upperPath, {forceLoading: true})
          }
          path = upperPath
        } catch (err) {
          console.error(err);
          return
        }
      } else if (err.code === "ERROR_NOT_A_FOLDER") {
        let upperPath = path.slice(0, -1)
        if(upperPath.length > 1 || upperPath[0] === "mnt") {
          await this.props.loadFileData(upperPath, {forceLoading: true})
        }
      }
    }
    this.props.changeLibraryPath(path)
  }

  componentWillReceiveProps(newprops) {
    if(this.props.update &&
      (newprops.selectedFiles !== this.props.selectedFiles ||
      newprops.navpath !== this.props.navpath)) {
      this.props.update(
        (newprops.selectedFiles !== this.props.selectedFiles) ? newprops.selectedFiles : null,
        (newprops.navpath !== this.props.navpath) ? newprops.navpath : null
      )
    }
    if(newprops.deselect !== this.props.deselect) {
      this.props.setSelectedFiles([])
    }
    if(newprops.overridePath instanceof Array) {
      let newOverride = newprops.overridePath.join('/')
      let oldOverride = this.props.overridePath ? this.props.overridePath.join('/') : ''
      let newPath = newprops.navpath.join('/')
      if(newOverride !== oldOverride &&
        newOverride !== newPath) {
        this.changePath(newprops.overridePath)
      }
    }
  }

  componentDidUpdate(prevprops) {
    if(this.props.searchParam && prevprops.searchParam !== this.props.searchParam) {
      this.props.search(this.props.searchParam)
    }
  }

  //TODO: This should probably be moved into the redux duck at some point
  selectFileRange(rpath) {
    let {
      filelist,
      selectedFiles,
      navpath,
      setSelectedFiles
    } = this.props

    if(selectedFiles.length === 0) {
      selectFile(rpath)
      return
    }
    let lastSelected = selectedFiles.slice(-1)[0]
    let startIndex = filelist.findIndex((item) => {
      return typeof item === 'object' ?
        item.name === lastSelected[lastSelected.length - 1] :
        item === lastSelected[lastSelected.length - 1]
    })
    let endIndex = filelist.findIndex((item) => {
      return typeof item === 'object' ?
        item.name === rpath[rpath.length - 1] :
        item === rpath[rpath.length - 1]
    })
    let toSelect = endIndex > startIndex ?
      filelist.slice(startIndex, endIndex + 1) :
      filelist.slice(endIndex, startIndex + 1)
    toSelect = toSelect.map((item) => {
      if(typeof item === 'object') {
        item = item.name
      }
      let fpath = navpath.join('/') + "/" + item
      if (fpath === "mnt/main/tv") return undefined
      return [...navpath, item]
    }).filter((item) => { return item !== undefined })
    setSelectedFiles(toSelect)
    /*
    for(let item of toSelect) {
      if(typeof item === 'object') {
        item = item.name
      }
      let selectIndex = selectedFiles.findIndex((selItem) => selItem.join('/') === [...navpath, item].join('/'))
      if(selectIndex === -1) {
        selectFile([...navpath, item])
      }
    }
    */
  }

  selectAllFiles = () => {
    let {
      filelist,
      selectedFiles,
      navpath,
      setSelectedFiles
    } = this.props
    if(selectedFiles.length === filelist.length) {
      setSelectedFiles([])
    } else {
      let toSelect = filelist.map((item) => {
        if(typeof item === 'object') {
          item = item.name
        }
        return [...navpath, item]
      })
      setSelectedFiles(toSelect)
    }
  }

  handleNavigateToFile = (path) => {
    if(path) {
      this.changePath(path)
    }
  }

  handleAction = (action, path) => {
    if(action.allSelected) {
      action.action(this.props.selectedFiles)
    } else {
      action.action(path)
    }
  }

  render () {
    let {
      filelist,
      fileData,
      navpath,
      selectedFiles,
      searchParam,
      compact,
      previewPath,
      previewType,
      consentFormPath,
      selectOne,
      noOpen,
      sortColumn,
      sortAsc,
      viewMode,
      selectFile,
      selectFileOnly,
      searchLibrary,
      search,
      inputAssociations,
      sortLibrary,
      previewFile,
      viewConsentForm,
      actionDropdown=[],
      handleOpenFile,
      handleSetInputAssociation,
      handleSetInOutPoints,
      selectedChannel
    } = this.props

    let filedata, highlightFile;

    if(searchParam) {
      filedata = getIn(fileData, ['search', searchParam])
    } else {
      let data = getIn(fileData, dataPath(navpath));
      if(data && data.type === "file") {
        highlightFile = navpath.slice(-1)[0]
        filedata = getIn(fileData, dataPath(navpath.slice(0, -1), true));
        navpath = navpath.slice(0, -1)
      } else {
        filedata = getIn(fileData, dataPath(navpath, true));
        if(!filedata) {
          filedata = {}
        }
      }
    }

    let LibraryView = viewMode === 'tile' ? LibraryFileTiles : LibraryFileTable

    let searchBar = null
    if(search) {
      searchBar = (<LibrarySearchBar searchParam={searchParam}
          searchLibrary={searchLibrary}
          search={search}
          id="searchbar-container"/>)
    }

    if(searchParam) {
      actionDropdown = [
        {action: this.handleNavigateToFile, display: 'Open In Library', icon: 'arrow right', key: 'open parent directory'},
        ...actionDropdown
      ]
    }

    actionDropdown = [
      ...actionDropdown,
      {action: viewConsentForm, display: 'View', icon: "file alternate outline", key: 'upload consent form', conditions: {hasConsentForm: true}, dropdownParent: 'Consent Form'}
    ]

    actionDropdown = actionDropdown.map((action) => ({
      ...action,
      action: (path) => this.handleAction(action, path)
    }))

    return (
      <>
        <Segment className='libraryNavBar' attached>
          <LibraryNavBar navpath={navpath}
            searchParam={searchParam}
            changeLibraryPath={this.changePath}/>
          <div className='libraryNavSpacer'/>
          {searchBar}
        </Segment>
        <LibraryView filelist={filelist}
          fileData={filedata}
          selectedFiles={selectedFiles}
          navpath={navpath}
          isSearching={!!searchParam}
          changeLibraryPath={this.changePath}
          compact={compact}
          selectOne={selectOne}
          noOpen={noOpen}
          selectFile={selectFile}
          selectFileOnly={selectFileOnly}
          selectFileRange={this.selectFileRange}
          selectAllFiles={this.selectAllFiles}
          sortColumn={sortColumn}
          sortAsc={sortAsc}
          sortLibrary={sortLibrary}
          previewFile={previewFile}
          handleOpenFile={handleOpenFile}
          inputAssociations={inputAssociations}
          handleSetInputAssociation={handleSetInputAssociation}
          actionDropdown={actionDropdown}
          highlightFile={highlightFile}
          selectedChannel={selectedChannel}/>
        <FilePreviewModal path={previewPath}
          handleSetInOutPoints={handleSetInOutPoints}
          fileData={fileData}
          type={previewType}
          closeModal={previewFile}/>
        <ConsentFormModal path={consentFormPath}
          closeModal={viewConsentForm}/>
      </>
    )
  }
}

let mapStateToProps = (state, props) => {
  let toReturn = {
    ...props,
    ...state
  }
  return {
    ...toReturn,
    filelist: LibrarySelector(toReturn)
  }
}

let mapDispatchToProps = (dispatch) => bindActionCreators({
  changeLibraryPath,
  selectFile,
  selectFileOnly,
  setSelectedFiles,
  sortLibrary,
  searchLibrary,
  previewFile,
  viewConsentForm
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(loadingIndicator(LibraryFrame))
