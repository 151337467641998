import React, { PureComponent } from 'react'
import {Button,
  Dropdown,
  Input,
  Label,
  Popup} from 'semantic-ui-react'

const LIVE_CAPTION_TRANSLATION_LANGUAGE_CODES = [
  {key: "af", text: "Afrikaans", value: "af"},
  {key: "sq", text: "Albanian", value: "sq"},
  {key: "am", text: "Amharic", value: "am"},
  {key: "ar", text: "Arabic", value: "ar"},
  {key: "hy", text: "Armenian", value: "hy"},
  {key: "az", text: "Azerbaijani", value: "az"},
  {key: "bn", text: "Bengali", value: "bn"},
  {key: "bs", text: "Bosnian", value: "bs"},
  {key: "bg", text: "Bulgarian", value: "bg"},
  {key: "ca", text: "Catalan", value: "ca"},
  {key: "zh", text: "Chinese (Simplified)", value: "zh"},
  {key: "zh-TW", text: "Chinese (Traditional)", value: "zh-TW"},
  {key: "hr", text: "Croatian", value: "hr"},
  {key: "cs", text: "Czech", value: "cs"},
  {key: "da", text: "Danish", value: "da"},
  {key: "fa-AF", text: "Dari", value: "fa-AF"},
  {key: "nl", text: "Dutch", value: "nl"},
//  {key: "en", text: "English", value: "en"}, Default Language is english; only uncomment this if we support non-english default language in the future
  {key: "et", text: "Estonian", value: "et"},
  {key: "fa", text: "Farsi (Persian)", value: "fa"},
  {key: "tl", text: "Filipino, Tagalog", value: "tl"},
  {key: "fi", text: "Finnish", value: "fi"},
  {key: "fr", text: "French", value: "fr"},
  {key: "fr-CA", text: "French (Canada)", value: "fr-CA"},
  {key: "ka", text: "Georgian", value: "ka"},
  {key: "de", text: "German", value: "de"},
  {key: "el", text: "Greek", value: "el"},
  {key: "gu", text: "Gujarati", value: "gu"},
  {key: "ht", text: "Haitian Creole", value: "ht"},
  {key: "ha", text: "Hausa", value: "ha"},
  {key: "he", text: "Hebrew", value: "he"},
  {key: "hi", text: "Hindi", value: "hi"},
  {key: "hu", text: "Hungarian", value: "hu"},
  {key: "is", text: "Icelandic", value: "is"},
  {key: "id", text: "Indonesian", value: "id"},
  {key: "ga", text: "Irish", value: "ga"},
  {key: "it", text: "Italian", value: "it"},
  {key: "ja", text: "Japanese", value: "ja"},
  {key: "kn", text: "Kannada", value: "kn"},
  {key: "kk", text: "Kazakh", value: "kk"},
  {key: "ko", text: "Korean", value: "ko"},
  {key: "lv", text: "Latvian", value: "lv"},
  {key: "lt", text: "Lithuanian", value: "lt"},
  {key: "mk", text: "Macedonian", value: "mk"},
  {key: "ms", text: "Malay", value: "ms"},
  {key: "ml", text: "Malayalam", value: "ml"},
  {key: "mt", text: "Maltese", value: "mt"},
  {key: "mr", text: "Marathi", value: "mr"},
  {key: "mn", text: "Mongolian", value: "mn"},
  {key: "no", text: "Norwegian (Bokmål)", value: "no"},
  {key: "ps", text: "Pashto", value: "ps"},
  {key: "pl", text: "Polish", value: "pl"},
  {key: "pt", text: "Portuguese (Brazil)", value: "pt"},
  {key: "pt-PT", text: "Portuguese (Portugal)", value: "pt-PT"},
  {key: "pa", text: "Punjabi", value: "pa"},
  {key: "ro", text: "Romanian", value: "ro"},
  {key: "ru", text: "Russian", value: "ru"},
  {key: "sr", text: "Serbian", value: "sr"},
  {key: "si", text: "Sinhala", value: "si"},
  {key: "sk", text: "Slovak", value: "sk"},
  {key: "sl", text: "Slovenian", value: "sl"},
  {key: "so", text: "Somali", value: "so"},
  {key: "es", text: "Spanish", value: "es"},
  {key: "es-MX", text: "Spanish (Mexico)", value: "es-MX"},
  {key: "sw", text: "Swahili", value: "sw"},
  {key: "sv", text: "Swedish", value: "sv"},
  {key: "ta", text: "Tamil", value: "ta"},
  {key: "te", text: "Telugu", value: "te"},
  {key: "th", text: "Thai", value: "th"},
  {key: "tr", text: "Turkish", value: "tr"},
  {key: "uk", text: "Ukrainian", value: "uk"},
  {key: "ur", text: "Urdu", value: "ur"},
  {key: "uz", text: "Uzbek", value: "uz"},
  {key: "vi", text: "Vietnamese", value: "vi"},
  {key: "cy", text: "Welsh", value: "cy"}
]

export default class TranslationsInput extends PureComponent {

  render() {
    let {value, onChange, label, ...rest} = this.props

    value = value || []
    if(typeof value === "string") {
      value = value.split(",")
    }

    let labelComponent = ""
    if(label) {
      labelComponent = <Label content={label}/>
    }

    return (
      <Input className="labeled" {...rest}>
        {labelComponent}
        <Dropdown
          placeholder='Languages'
          fluid
          multiple
          selection
          search
          value={value}
          onChange={(e, data) => {onChange(e, {value: data.value.join(",")})}}
          options={LIVE_CAPTION_TRANSLATION_LANGUAGE_CODES}
        />
        <Popup wide="very" trigger={<Button icon="question"/>}>
          <Popup.Header>
            Live Caption Translations
          </Popup.Header>
          <Popup.Content>
            <span>NOTE: Due to limitations of cable television- 608 captions include a total of four languages, the first of which is English, followed by the additional three languages selected.  With 708 captions, a total of six languages will be included, the first of which is English, followed by the other five languages selected. If additional languages are selected, they will stream out to the web but will be omitted from your cable broadcast. <b>The order in which you select the languages will determine the order they are available and any that extend past the limits previously mentioned will only be included on web streams.</b>  Languages will be saved in this window and automatically applied for future caption/translation broadcasts.</span>
          </Popup.Content>
        </Popup>
      </Input>
    )
  }

}
