import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {libraryActions} from 'redux/file_list'
import {bindActionCreators} from 'redux'
import {Icon, Image} from 'semantic-ui-react'
import {actions as settingsActions} from 'redux/settings'
import {assignItemToChannel, playItemNow, playItemNext} from 'redux/channel'
import {openApplication} from 'redux/menu'
import {checkHasAccessToApp} from 'helpers/authentication_helpers'
import {dataPath, fileTypeInfo} from 'helpers/library_helpers'
import {getIn, encodeURIFilepath} from 'helpers/general_helpers'
import {messages} from 'redux/messages'
import {actions as metadataActions} from 'redux/applications/metadata_editor.js'
import {actions as cloudActions} from 'redux/applications/cloud.js'
import moment from "moment"

import {staticAsset} from 'helpers/net_helpers'

import Library from 'components/Library/Library'
import SendToVodModal from 'components/Library/FileSendToVodModal'

const EDIT_ACTIONS = [
  {type: 'playlist', subtype: 'simple', icon: (
    <Icon className='svg-icon'>
      <Image src={staticAsset("/icons/simpleplaylist_black.svg")}/>
    </Icon>
    ), app: 'SimplePlaylist', display: 'Edit Playlist'},
  {type: 'playlist', icon: (
    <Icon className='svg-icon'>
      <Image src={staticAsset("/icons/castus_cuts_black.svg")}/>
    </Icon>
    ), app: 'Playlist', display: 'Edit CASTUS Cuts Project'},
  {type: 'schedule', icon: (
    <Icon className='svg-icon'>
      <Image src={staticAsset("/icons/schedule_black.svg")}/>
    </Icon>
    ), app: 'Schedule', display: 'Edit Schedule'},
  {type: 'scrolling-text', icon: (
    <Icon className='svg-icon'>
      <Image src={staticAsset("/icons/scrollingtext_black.svg")}/>
    </Icon>
    ), app: 'ScrollingText', display: 'Edit Scrolling Text'},
  {type: 'rss-feed', icon: (
    <Icon className='svg-icon'>
      <Image src={staticAsset("/icons/rssfeed_black.svg")}/>
    </Icon>
    ), app: 'RSSItemEditor', display: 'Edit RSS Feed Item'},
  {type: 'metadata-template', icon: (
    <Icon className='svg-icon'>
      <Image src={staticAsset("/icons/metadatatemplate_black.svg")}/>
    </Icon>
    ), app: 'MetadataTemplate', display: 'Edit Metadata Template'},
]

export class LibraryContainer extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {deselect: 0, sendToVodFiles: []}
  }

  componentDidUpdate(prevProps) {
    if(prevProps.ownProps.deselect !== this.props.ownProps.deselect) {
      this.setState({deselect: this.props.ownProps.deselect})
    }
  }

  deselect = () => {
    this.setState({deselect: Date.now()})
  }

  // Handler for copy button
  handleFileCopy = (selectedFiles) => {
    this.props.grabFiles('copy', selectedFiles)
    this.deselect()
  }

  // Handler for cut button
  handleFileCut = (selectedFiles) => {
    this.props.grabFiles('move', selectedFiles)
    this.deselect()
  }

  // Handler for paste button
  handleFilePaste = (targetPath) => {
    let {clipboard, fileData} = this.props.file_list
    if(getIn(fileData, dataPath(targetPath)).type !== 'folder') {
      targetPath = targetPath.slice(0, -1)
    }
    if(clipboard.mode === 'copy') {
      this.props.copyFiles(clipboard.files.map(file => [file, targetPath.concat(file[file.length - 1])]))
      .then(() => {this.props.grabFiles('', [])})
    } else if(clipboard.mode === 'move') {
      this.props.moveFiles(clipboard.files.map(file => [file, targetPath.concat(file[file.length - 1])]))
      .then(() => {this.props.grabFiles('', [])})
    }
  }

  // Handler for rename button
  handleFileRename = (selectedFiles) => {
    for(let file of selectedFiles) {
      this.props.prompt(`What do you want to rename ${file[file.length - 1]} to?`, (newName) => {
        if(newName !== null) {
          this.props.renameFile(file, newName)
        }
        this.deselect()
      }, {initialValue: file[file.length - 1], validate: (res) => (res !== ''), invalidText: 'That is not a valid file name.'})
    }
  }

  changeMetadataDateTime = async (id, path) => {
    this.props.prompt(`What do you want to change ${path}'s ${id} to?`, async (newDate) => {
      if(newDate !== null) {
        // Reformat time string to correct one
        let temp = moment.utc(newDate) // Convert to UTC
        let fixDate = temp.format('YYYY-MM-D hh:mm:ss UTC') // Reformat

        // Save metadata
        await this.props.saveMetadata({[id]: fixDate}, path)
      }
    }, {type: "dateTime", validate: (res) => (res !== ''), invalidText: 'This is not a valid date'})
  }

  changeMetadata = async (id, path) => {
    this.props.prompt(`What do you to change ${path}'s ${id} to?`, async (newName) => {
      if (newName !== null) {
        await this.props.saveMetadata({[id]: newName}, path)
      }
    }, {initialValue: "", validate: (res) => (res !== ''), invalidText: 'That is not a valid title'})
  }

  // Handle add title
  handleFileTitle = (selectedFiles) => {
    this.changeMetadata("title", selectedFiles[0])
    this.deselect()
  }

  handleFileDescription = (selectedFiles) => {
    this.changeMetadata("description", selectedFiles[0])
    this.deselect()
  }

  handleFileGoLive = (selectedFiles) => {
    this.changeMetadataDateTime("content window open", selectedFiles[0])
    this.deselect()
  }

  handleFileExpiration = (selectedFiles) => {
    this.changeMetadataDateTime("content window close", selectedFiles[0])
    this.deselect()
  }

  // Handler for creating directories
  handleCreateFolder = () => {
    let {navPath} = this.props
    this.props.prompt('What do you want the directory to be called?', (dirName) => {
      if(dirName !== null) {
        this.props.createFolder(navPath, dirName)
      }
    }, {initialValue: 'NEW_FOLDER'})
  }

  // Handler for delete button
  handleFileDelete = async (selectedFiles) => {
    let {confirmThenDeleteFiles} = this.props
    await confirmThenDeleteFiles(selectedFiles)
    if(this.props.update && this.props.overridePath && selectedFiles.find(path => path.join("/") === this.props.overridePath.join("/"))) {
      this.props.update(null, this.props.overridePath.slice(0, -1))
    }
    this.deselect()
  }

  /**
   * Factory function for creating handlers for editing files in other apps
   */
  handleEditInApp = (appName) => {
    return (path) => {
      let openFile = getIn(this.props, ['ownProps', 'handleOpenFile'])
      if(!openFile) {
        openFile = this.handleOpenFile
      }
      openFile(path, {application: appName});
      this.deselect()
    }
  }

  // Handler for file download
  handleFileDownload = (selectedFiles) => {
    for(let file of selectedFiles) {
      this.props.downloadFile(file)
    }
    this.deselect()
  }

  handleSendToVod = (selectedFiles) => {
    let {fileData} = this.props.file_list
    let toSend = selectedFiles.map((fpath) => {
      let type = getIn(fileData, [...dataPath(fpath), "metadata", "file type"])
      let info = type && fileTypeInfo(type)
      let captions = getIn(fileData, [...dataPath(fpath), "captions"])
      return {
        path: fpath,
        icon: info && info.icon,
        captions
      }
    })
    this.setState({sendToVodFiles: toSend})
    this.deselect()
  }

  sendToVod = (files, opts={}) => {
    this.props.openApplication('Upload')
    for(let path of files) {
      this.props.sendToVod(path.slice(0, -1), path[path.length - 1], opts).catch((err) => {
        console.error(err)
      })
    }
  }

  closeSendToVodModal = () => {
    this.setState({sendToVodFiles: []})
  }

  handleOpenFile = (path, type) => {
    if(type.application) {
      path = encodeURIFilepath(path).join('/')
      this.props.openApplication(type.application, `/${path}`)
      this.deselect()
    }
  }

  handlePasteMetadata = (selectedFiles) => {
    for(let file of selectedFiles) {
      this.props.copyMetadata(file)
    }
    this.deselect()
  }

  handleGenerateMetadata = (selectedFiles) => {
    for(let file of selectedFiles) {
      this.props.generateMetadata(file)
    }
    this.deselect()
  }

  handleEjectDrive = (path) => {
    this.props.ejectDrive(path)
    this.deselect()
  }

  handleMountDrive = (path) => {
    this.props.mountDrive(path)
    this.deselect()
  }

  handleMountDriveReadOnly = (path) => {
    this.props.mountDrive(path, true)
    this.deselect()
  }

  handleUnmountDrive = (path) => {
    this.props.unmountDrive(path)
    this.deselect()
  }

  handleSetInputAssociation = (path, id) => {
    this.props.setInputAssociation(path, id)
  }

  handleSetInOutPoints = async (path, inPoint, outPoint) => {
    let swapData = {}
    if(typeof inPoint === "number" && !isNaN(inPoint)) {
      swapData.in = inPoint
    }
    if(typeof outPoint === "number" && !isNaN(outPoint)) {
      swapData.out = outPoint
    }
    await this.props.saveMetadata(swapData, path)
    this.props.alert("In and Out points successfully set.", {level: "success"})
  }

  handleDownloadConsentForm = (path) => {
    this.props.downloadConsentForm(path)
    this.deselect()
  }

  render() {
    let {file_list,
      loadFileData,
      setSearchNext,
      appAccess,
      clipboardMetadata,
      assignItemToChannel,
      playItemNow,
      playItemNext,
      active_channel,
      vodConfig,
      cloudPlaylists,
      cloudPlaylistsError,
      fetchCloudPlaylists,
      clearPlaylistsError,
      ownProps} = this.props
    let {deselect} = this.state

    let actionDropdown = []

    let channelNumber = /\d+/.exec(active_channel)
    if(channelNumber) {
      channelNumber = channelNumber[0]
    } else {
      channelNumber = ''
    }

    // While it seems weird to be able to check for access to library within the library,
    //  the library component can still be displayed from other apps.
    //  If the user has access to other apps but not the library, let them see the library for use with other apps,
    //  but don't allow them to make other changes to the library (naturally being able to save to
    //  the library would mean editing the library, but being able to access editors of different files without
    //  saving wouldn't make sense).
    if(checkHasAccessToApp(appAccess, 'Library')) {
      let editActions = []
      if(!ownProps.noOpen) {
        editActions = EDIT_ACTIONS.filter((action) => {
          return checkHasAccessToApp(appAccess, action.app)
        }).map((action) => {
          let conditions = {}
          if(action.type) {
            conditions.type = action.type
          }
          if(action.subtype) {
            conditions.subtype = action.subtype
          }
          conditions.typeNot = "filesystem"
          return {action: this.handleEditInApp(action.app), icon: action.icon, display: action.display, key: action.type + (action.subtype ? action.subtype : ''), conditions}
        })
      }

      let playActions = checkHasAccessToApp(appAccess, 'Settings') ?
        [
          {action: playItemNow, icon: 'play', display: `Play Item Now on Channel ${channelNumber}`, conditions: {typeNot: "filesystem", notTv: true}, key: 'play item now'},
          {action: playItemNext, icon: (
            <Icon className='svg-icon'>
              <Image src={staticAsset("/icons/play next.svg")}/>
            </Icon>
            ), display: `Play Item Next on Channel ${channelNumber}`,
            conditions: {typeNot: "filesystem", notTv: true},
            key: 'play item next'},
          {action: assignItemToChannel, icon: (
            <Icon className='svg-icon'>
              <Image src={staticAsset("/icons/assign-item.svg")}/>
            </Icon>
            ),
            display: `Assign Item to Channel ${channelNumber}`,
            conditions: {typeNot: "filesystem", notTv: true},
            key: 'assign item to channel'}
        ] :
        []

      let metadataActions = checkHasAccessToApp(appAccess, 'MetadataEditor') ?
        [
          {action: this.handleEditInApp('MetadataEditor'), icon: (
            <Icon className='svg-icon'>
              <Image src={staticAsset("/icons/metadataeditor_black.svg")}/>
            </Icon>
            ), display: 'Edit Metadata', conditions: {typeNot: "filesystem", notTv: true}, key: 'metadata', dropdownParent: 'Metadata'},
          {action: this.handleGenerateMetadata, icon: (
            <Icon.Group className='svg-icon-group icon'>
              <Icon className='svg-icon'>
                <Image src={staticAsset("/icons/metadataeditor_black.svg")}/>
              </Icon>
              <Icon corner name="plus"/>
            </Icon.Group>
            ), conditions: {typeNot: "filesystem", notTv: true}, display: 'Generate Metadata',
            key: 'generate-metadata',
            allSelected: true, dropdownParent: 'Metadata'},
          {action: clipboardMetadata, icon: (
            <Icon.Group className='svg-icon-group icon'>
              <Icon className='svg-icon'>
                <Image src={staticAsset("/icons/metadataeditor_black.svg")}/>
              </Icon>
              <Icon corner name="copy"/>
            </Icon.Group>
            ), display: 'Copy Metadata',
            conditions: {typeNot: ["filesystem", "metadata-template"], notTv: true},
            key: 'copy-metadata', dropdownParent: 'Metadata'},
          {action: clipboardMetadata, icon: (
            <Icon.Group className='svg-icon-group icon'>
              <Icon className='svg-icon'>
                <Image src={staticAsset("/icons/metadataeditor_black.svg")}/>
              </Icon>
              <Icon corner name="copy"/>
            </Icon.Group>
            ), display: 'Copy Template Metadata',
            conditions: {type: "metadata-template", notTv: true},
            key: 'copy-template-metadata', dropdownParent: 'Metadata'},
          {action: this.handlePasteMetadata, icon: (
            <Icon.Group className='svg-icon-group icon'>
              <Icon className='svg-icon'>
                <Image src={staticAsset("/icons/metadataeditor_black.svg")}/>
              </Icon>
              <Icon corner name="paste"/>
            </Icon.Group>
            ), display: 'Paste Metadata',
            key: 'paste-metadata',
            conditions: {typeNot: "filesystem", notTv: true},
            allSelected: true, dropdownParent: 'Metadata'}
        ] :
        []

      let filesystemActions = [
          {action: this.handleFileCopy, icon: 'copy', display: 'Copy', key: 'copy', conditions: {typeNot: "filesystem", notTv: true}, allSelected: true, dropdownParent: 'File'},
          {action: this.handleFileCut, icon: 'cut', display: 'Move', key: 'cut', conditions: {typeNot: "filesystem", notTv: true}, allSelected: true, dropdownParent: 'File'},
          {action: this.handleFilePaste, icon: 'paste', display: 'Paste', conditions: {typeNot: "filesystem", notTv: true}, key: 'paste', dropdownParent: 'File'},
          {action: this.handleFileRename, icon: 'text cursor', display: 'Rename', conditions: {typeNot: "filesystem", notTv: true}, key: 'rename', allSelected: true, dropdownParent: 'File'},
          {action: this.handleFileDownload, icon: 'download', display: 'Download', key: 'download', conditions: {typeNot: "filesystem", notTv: true}, allSelected: true, dropdownParent: 'File'},
          {action: this.handleFileDelete, icon: 'remove', display: 'Delete', key: 'delete', conditions: {typeNot: "filesystem", notTv: true}, allSelected: true, dropdownParent: 'File'}
        ]

      let mountActions = [
          {action: this.handleMountDrive, display: "Mount", key: "mount", conditions: {type: "filesystem", subtype: "unmounted", notTv: true}, args: [["data", "data", "dev"]]},
          {action: this.handleMountDriveReadOnly, display: "Mount (Read Only)", key: "mount_read_only", conditions: {type: "filesystem", subtype: "unmounted", notTv: true}, args: [["data", "data", "dev"]]},
          {action: this.handleUnmountDrive, display: "Unmount", key: "unmount", conditions: {type: "filesystem", subtype: "mounted", driveNotMain: true, notTv: true}, args: [["data", "data", "dev"]]},
          {action: this.handleEjectDrive, display: "Eject", key: "eject", conditions: {typeRaw: "cdrom", subtypeNot: "mounted", driveNotMain: true, notTv: true}, args: [["data", "data", "dev"]]}
        ]

      let tagActions = [
        {action: this.handleFileTitle, display: "Add Title", key: "add_title", conditions: {typeNot: "filesystem", notTv: true}, allSelected: true, dropdownParent: 'Add Tags'},
        {action: this.handleFileDescription, display: "Add Description", key: "add_description", conditions: {typeNot: "filesystem", notTv: true}, allSelected: true, dropdownParent: 'Add Tags'},
        {action: this.handleFileGoLive, display: "Add Go Live", key: "add_go_live", conditions: {typeNot: "filesystem", notTv: true}, allSelected: true, dropdownParent: 'Add Tags'},
        {action: this.handleFileExpiration, display: "Add Expiration", key: "add_expiration", conditions: {typeNot: "filesystem", notTv: true}, allSelected: true, dropdownParent: 'Add Tags'}
      ]

      let otherActions = [
        {action: this.handleDownloadConsentForm, display: "Download", icon: "download", key: "download_consent_form", conditions: {hasConsentForm: true}, dropdownParent: 'Consent Form'}
      ]

      actionDropdown = [
        ...filesystemActions,
        ...tagActions,
        ...editActions,
        ...playActions,
        ...mountActions,
        ...metadataActions,
        ...otherActions
      ]
    }

    if(checkHasAccessToApp(appAccess, 'Upload')) {
      actionDropdown.push(
        {action: this.handleSendToVod, icon: 'send', display: 'Send To Video On Demand', key: 'send to vod', conditions: {type: ['video', 'playlist'], typeNot: 'filesystem', notTv: true}, allSelected: true}
      )
    }

    //Prevent multiple slashes in url
    let {overridePath} = ownProps
    if(overridePath) {
      if(typeof overridePath === "string") {
        overridePath = overridePath.replace(/\/+/g, "/")
      } else if (overridePath instanceof Array) {
        overridePath = overridePath.filter((component) => component !== "")
      }
    }

    return (
      <>
        <Library actionDropdown={actionDropdown}
          loadFileData={loadFileData}
          handleOpenFile={this.handleOpenFile}
          handleSetInputAssociation={this.handleSetInputAssociation}
          handleSetInOutPoints={this.handleSetInOutPoints}
          search={setSearchNext}
          selectedChannel={channelNumber}
          {...file_list}
          {...ownProps}
          overridePath={overridePath}
          deselect={deselect}/>
        <SendToVodModal files={this.state.sendToVodFiles}
          vodConfig={vodConfig}
          cloudPlaylists={cloudPlaylists}
          playlistsError={cloudPlaylistsError}
          sendToVod={this.sendToVod}
          fetchCloudPlaylists={fetchCloudPlaylists}
          clearPlaylistsError={clearPlaylistsError}
          closeModal={this.closeSendToVodModal}/>
      </>
    )
  }

}

const mapStateToProps = (state, ownProps) => ({
  ownProps,
  file_list: state.file_list,
  active_channel: state.channel.active_channel,
  appAccess: state.server.appAccess,
  vodConfig: state.cloud.vodConfig,
  cloudPlaylists: state.cloud.cloudPlaylists,
  cloudPlaylistsError: state.cloud.cloudPlaylistsFetchError,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    ...libraryActions,
    ...messages,
    openApplication,
    saveServiceSettings: settingsActions.saveServiceSettings,
    assignItemToChannel,
    playItemNow,
    playItemNext,
    dispatchCall: metadataActions.dispatchCall,
    fetchCloudPlaylists: cloudActions.fetchCloudPlaylists,
    clearPlaylistsError: cloudActions.clearCloudPlaylistError,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LibraryContainer)
