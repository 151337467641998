import {fetchFromServer, connectToServerSocket} from 'helpers/net_helpers'
import {getIn, setIn, encodeURIFilepath} from 'helpers/general_helpers'
import {messages} from 'redux/messages'
import {actions as settingsActions} from 'redux/settings'

export const ACTIVATE_CHANNEL = Symbol('active channel')
export const TOGGLE_SIDEBAR   = Symbol('toggle sidebar')
export const ESTABLISH_MONITOR_CONNECTION = Symbol('establish monitor connection')
export const SET_CONFIDENCE_MONITOR_IMAGE = Symbol('set confidence monitor image')
export const DID_LOAD_MONITOR_IMAGE = Symbol('did load monitor image')
export const LIST_SCHEDULE_SETS = Symbol('list schedule sets')

export const startConfidenceMonitors = () => {
  return async (dispatch, getState) => {
    if(getState().channel.monitorConnection !== null) {
      return
    }
    var connection = connectToServerSocket("/confidence_monitors")
    if(connection === undefined) {
      return
    }
    dispatch({
      type: ESTABLISH_MONITOR_CONNECTION,
      payload: connection
    })
    connection.on('initialize', (data) => {
      Object.entries(data).forEach(([key, thumb]) => {
        dispatch(setConfidenceMonitorImage(key, thumb))
      })
    })

    connection.on("update_monitor", (data) => {
      if(getState().channel.sidebar_out) {
        dispatch(setConfidenceMonitorImage(data.id, data.data))
      }
    })
  }
}

const setConfidenceMonitorImage = (id, data) => ({
  type: SET_CONFIDENCE_MONITOR_IMAGE,
  payload: {
    id,
    data
  }
})

export const didLoadMonitorImage = (id, url) => ({
  type: DID_LOAD_MONITOR_IMAGE,
  payload: {
    id,
    url
  }
})

export const assignItemToChannel = (path) => {
  return async (dispatch, getState) => {
    if(!(await dispatch(messages.confirmAsync(
      "Assign this item to play as the currently selected channel's default item?"
    )))) {
      return
    }
    let targetChannel = getState().channel.active_channel
    dispatch(settingsActions.saveServiceSettings('channel', targetChannel, {settings: {
      regions: {
        item: {
          media: {
            path: ['', ...path].join("/")
          }
        }
      }}
    }))
  }
}

export const playItemNow = (path) => {
  return async (dispatch, getState) => {
    if(!(await dispatch(messages.confirmAsync(
      "This will cause the item to play on the currently selected channel, interrupting what is currently playing. Continue?"
    )))) {
      return
    }
    let targetChannel = getState().channel.active_channel
    let response = await fetchFromServer(`/v2/services/channel/${targetChannel}/play_now/${encodeURIFilepath(path).join('/')}`, {
      method: 'POST'
    })
    if(!response.ok) {
      console.error("Error playing item on channel")
    }
  }
}

export const playItemNext = (path) => {
  return async (dispatch, getState) => {
    if(!(await dispatch(messages.confirmAsync(
      "This will cause the item to play on the currently selected channel at the end of the current queue (it will interrupt the regularly scheduled programming). Continue?"
    )))) {
      return
    }
    let targetChannel = getState().channel.active_channel
    let response = await fetchFromServer(`/v2/services/channel/${targetChannel}/play_next/${encodeURIFilepath(path).join('/')}`, {
      method: 'POST'
    })
    if(!response.ok) {
      console.error("Error playing item on channel")
    }
  }
}

const leadzeropad = (s,l) => {
  s = s.toString()
  while (s.length < l) s = '0' + s
  return s
}

export const setChannelIntervals = (basis, duration) => {
  return async (dispatch, getState) => {
    // The schedule patcher only needs the date, not the whole timestamp
    let datestr = leadzeropad(basis.getFullYear(),4)+'/'+leadzeropad(basis.getMonth()+1,2)+'/'+leadzeropad(basis.getDate(),2);
    let response = await fetchFromServer("/v2/services/channel/set_all_intervals", {
      method: 'POST',
      body: JSON.stringify({basis: datestr, duration}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if(response.ok) {
      dispatch(messages.alert(`Successfully changed all channel schedule intervals to start on ${basis.toDateString()} and last for ${duration} day(s).`, {level: "success"}))
    } else {
      console.error(response)
      let err = await response.text()
      dispatch(messages.alert(`There was an error changing the channel schedule intervals: ${err}`, {level: "error"}))
    }
  }
}

export const listScheduleSets = () => {
  return async (dispatch, getState) => {
    try {
      let res = await fetchFromServer("/v2/services/schedule-sets")
      if(res.ok) {
        let data = await res.json()
        data = data.map((set) => set.name)
        dispatch({type: LIST_SCHEDULE_SETS, payload: data})
      } else {
        console.error(res)
        let err = await res.text()
        dispatch(messages.alert(`There was an error fetching the list of schedule sets: ${err}`, {level: "error"}))
      }
    } catch (err) {
      console.error(err)
      dispatch(messages.alert(`There was an error fetching the list of schedule sets: ${err.message}`, {level: "error"}))
    }
  }
}

export const createScheduleSet = (name, basis, duration) => {
  return async (dispatch, getState) => {
    let sets = getState().channel.scheduleSets
    let exists = sets.find((set) => set === name)
    if(exists) {
      dispatch(messages.alert("A schedule set already exists with that name. The names of schedule sets must be unique.", {level: "error"}))
      return
    }
    try {
      basis = `${basis.getFullYear()}/${basis.getMonth() + 1}/${basis.getDate()}`
      let res = await fetchFromServer("/v2/services/schedule-sets/create", {
        method: 'POST',
        body: JSON.stringify({name, basis, duration}),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if(res.ok) {
        dispatch(messages.alert(`Successfully created schedule set ${name}`, {level: "success"}))
        return dispatch(listScheduleSets)
      } else {
        console.error(res)
        let err = await res.text()
        dispatch(messages.alert(`There was an error creating the schedule set ${name}: ${err}`, {level: "error"}))
      }
    } catch (err) {
      console.error(err)
      dispatch(messages.alert(`There was an error creating the schedule set ${name}: ${err.message}`, {level: "error"}))
    }
  }
}

export const applyScheduleSet = (name) => {
  return async (dispatch, getState) => {
    try {
      let res = await fetchFromServer(`/v2/services/schedule-sets/apply/${name}`, {method: 'POST'})
      if(res.ok) {
        dispatch(messages.alert(`Changed to schedule set ${name}`, {level: "success"}))
      } else {
        console.error(res)
        let err = await res.text()
        dispatch(messages.alert(`There was an error applying the schedule set ${name}: ${err}`, {level: "error"}))
      }
    } catch (err) {
      console.error(err)
      dispatch(messages.alert(`There was an error applying the schedule set ${name}: ${err.message}`, {level: "error"}))
    }
  }
}

/**
 * Selects a channel preview
 *
 * {Integer} index The 0 offset index of the channel
 */

export const activate_channel = index => {
  return (dispatch, state) => {
    dispatch({
      type: ACTIVATE_CHANNEL,
      payload: index,
    })
  }
}

/**
 * Toggel the channel sidebar.
 */
export const toggle_sidebar = () => {
  return (dispatch, state) => {
    dispatch({
      type: TOGGLE_SIDEBAR,
    })
  }
}

const initialState = {
  active_channel: 'channel1',
  sidebar_out: true,
  confidence_thumbs: {},
  monitorConnection: null,
  queueInfo: {},
  scheduleSets: [],
}

export default (state = initialState, action) => {
  let {type, payload} = action

  switch (type) {
    case ESTABLISH_MONITOR_CONNECTION: {
      return {
        ...state,
        montiorConnection: payload
      }
    }
    case SET_CONFIDENCE_MONITOR_IMAGE: {
      let {id, data} = payload
      if(!(data instanceof ArrayBuffer)) {
        return state
      }
      if(!id || !id[1]) {
        return state
      }
      let type = 'image/jpeg'
      let buf = new Uint8Array(data)
      if(String.fromCharCode(...buf.slice(0, 4)) === "RIFF" && String.fromCharCode(...buf.slice(8, 12)) === "WEBP") {
        type = 'image/webp'
      } else if(buf.slice(0, 8).join(" ") === "137 80 78 71 13 10 26 10") {
        type = 'image/png'
      }
      let thumb = new Blob([data], {type})
      if(thumb.size === 0) {
        return state
      }
      let url = URL.createObjectURL(thumb)
      return setIn(state, ['confidence_thumbs', id, 'current'], url)
    }
    case DID_LOAD_MONITOR_IMAGE: {
      let {id, url} = payload
      if(getIn(state, ['confidence_thumbs', id, 'backup'])) {
        URL.revokeObjectURL(state.confidence_thumbs[id].backup)
      }
      return setIn(state, ['confidence_thumbs', id, 'backup'], url)
    }
    case ACTIVATE_CHANNEL:
      return {
        ...state,
        active_channel: payload,
      }
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar_out: !state.sidebar_out,
      }
    case LIST_SCHEDULE_SETS:
      return {
        ...state,
        scheduleSets: payload
      }
    default:
      return state
  }
}
