import { setIn, deleteIn } from 'helpers/general_helpers'
import { fetchFromServer } from 'helpers/net_helpers'
import {checkHasAccessToApp} from 'helpers/authentication_helpers'
import applicationList from 'applicationList'

export const GET_USERS = Symbol('Get users')
export const DELETE_USER = Symbol('Delete users')
export const UPDATE_USER = Symbol('Update users')
export const NEW_USER = Symbol('Update users')
export const TOGGLE_PERMISSION = Symbol('Toggle Permission')

export const actions = {
    get_users: () => {
        return async (dispatch, getState) => {
            try {
                let users = await fetchFromServer('/v2/users')
                users = await users.json()
                dispatch({
                    type: GET_USERS,
                    payload: users
                })
            } catch (e) {
                console.log(e)
            }
        }
    },
    delete_user: id => {
        return async (dispatch, getState) => {
            try {
                await fetchFromServer(`/v2/user/${id}`, {
                    method: 'DELETE'
                });
                dispatch({
                    type: DELETE_USER,
                    payload: id
                })
            } catch (e) {
                console.log(e)
            }
        }
    },
    change_user_password: (id, password) => {
      return async (dispatch, getState) => {
            try {
                let user = getState().user_list.users.find(u => u.id === id);

                await fetchFromServer(`/v2/user/${id}`, {
                    method: 'POST',
                    body: JSON.stringify({
                        username: user.username,
                        password
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                dispatch({
                    type: UPDATE_USER,
                    payload: user
                });

            } catch (e) {
                console.log(e)
            }
        }
    },
    update_user: id => {
        return async (dispatch, getState) => {
            try {
                let user = getState().user_list.users.find(u => u.id === id);

                await fetchFromServer(`/v2/user/${id}`, {
                    method: 'POST',
                    body: JSON.stringify({
                        username: user.username,
                        access: JSON.stringify(user.access)
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                dispatch({
                    type: UPDATE_USER,
                    payload: user
                });

            } catch (e) {
                console.log(e)
            }
        }
    },
    new_user: user => {
        return async (dispatch, getState) => {
            try {
                let new_user = await fetchFromServer('/v2/user', {
                    method: 'POST',
                    body: JSON.stringify(user),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                new_user = await new_user.json()
                dispatch({
                    type: NEW_USER,
                    payload: new_user
                })
            } catch (e) {
                console.log(e);
            }
        }
    },
    togglePermission: (user_id, permission) => ({
        type: TOGGLE_PERMISSION,
        payload: { user_id, permission }
    })
}

const initialState = {
    users: [],
    dirtyUsers: {},
};

export default (state = initialState, action) => {
    let { type, payload } = action
    switch (type) {
        case GET_USERS: {
            let users = payload.sort((a, b) => a.username.localeCompare(b.username))
            return { ...state, users };
        }
        case DELETE_USER: {
            let users = state.users.filter(user => user.id !== payload)
            return { ...state, users };
        }
        case TOGGLE_PERMISSION: {
            let { user_id, permission } = payload
            state = setIn(state, ["dirtyUsers", user_id], true)
            state.users = state.users.map((user) => {
              if(user.id === user_id) {
                // clone
                user = {...user}
                //toggle this permission
                if (checkHasAccessToApp(user.access, permission)) {
                    if(user.access === "*" || user.access.includes("*")) {
                      user.access = Object.keys(applicationList)
                    }
                    // remove permission
                    user.access = user.access.filter(a => a !== permission)
                } else {
                    // add permission
                    user.access = [...user.access, permission]
                }
              }
              return user
            })
            return state
        }
        case UPDATE_USER: {
            let { id } = payload
            return {
              ...state,
              dirtyUsers: deleteIn(state.dirtyUsers, [id])
            }
        }
        case NEW_USER: {
            let new_state = { ...state }
            new_state.users = [...new_state.users, payload]
            new_state.users.sort((a, b) => a.username.localeCompare(b.username))
            return new_state
        }
        default:
            return state
    }
}


