import React, {PureComponent} from 'react'
import Library from 'containers/components/LibraryComponent'
import {connect} from 'react-redux'
import {dataPath} from 'helpers/library_helpers'
import {getIn} from 'helpers/general_helpers'

import './DirectorySelector.css'

class DirectorySelector extends PureComponent {

  handleLibraryUpdate = (selectedFiles=null, navPath=null) => {
    let {onChange, name, fileData} = this.props
    if(selectedFiles &&
      selectedFiles.length > 0) {
      if(getIn(fileData, [...dataPath(selectedFiles[0]), 'type']) === 'folder') {
        onChange(null, {name, value: selectedFiles[0]})
      } else {
        onChange(null, {name, filename: selectedFiles[0].slice(-1)[0]})
      }
    } else if(navPath) {
      onChange(null, {name, value: navPath})
    }
  }

  render() {
    let {value, deselect} = this.props
    return (
      <div className='directoryModalLibrary'>
        <Library compact
          selectOne
          noOpen
          notExistOk
          deselect={deselect}
          overridePath={value}
          update={this.handleLibraryUpdate}
          handleOpenFile={() => {}}/>
      </div>
    )
  }

}

const mapStateToProps = state => ({
  fileData: state.file_list.fileData
})

export default connect(mapStateToProps)(DirectorySelector)
