import React, {PureComponent} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actions} from 'redux/applications/upload'
import {loadFileData} from 'redux/file_list'
import {messages} from 'redux/messages'
import {openApplication} from 'redux/menu'
import {actions as cloudActions} from 'redux/applications/cloud'
import mergeMetadata from 'selectors/MergeMetadataSelector'
import getSendToVodOptions from 'selectors/AutoSendToVodOptionsSelector'
import FileTransfer from 'components/FileTransfer/Transfer'
import UploadControls from 'components/Upload/Container'
import {Header, Segment, Divider, Button, Icon} from 'semantic-ui-react'

import './Upload.css'

const METADATA_TEMPLATE_PATH = ['mnt', 'main', 'Metadata Templates']

class Upload extends PureComponent {

  componentDidMount() {
    this.props.loadFileTransfers()
    this.props.loadFileData(METADATA_TEMPLATE_PATH)
    let uploadPath = /to=([^&]+)/.exec(this.props.location.search)
    if(uploadPath) {
      this.props.changeDestination(decodeURIComponent(uploadPath[1]))
    }
  }

  handleEditMetadata = (path) => {
    this.props.openApplication('MetadataEditor', path.join('/'))
  }

  handleClearAll = () => {
    let {fileTransfers, fileTransferList, clearFileTransfer} = this.props
    for(let id of fileTransferList) {
      if(fileTransfers[id].state !== "ongoing" && fileTransfers[id].state !== "paused") {
        clearFileTransfer(id)
      }
    }
  }

  render() {
    let {fileTransfers,
      fileTransferList,
      destination,
      template,
      metadata,
      templateData,
      fileData,
      metadataTags,
      timezone,
      config={},
      vodUploadOptions,
      cloudPlaylists,
      cloudPlaylistsFetchError,
      fetchCloudPlaylists,
      clearCloudPlaylistError,
      startUpload,
      pauseFileTransfer,
      resumeFileTransfer,
      cancelFileTransfer,
      clearFileTransfer,
      restartFileTransfer,
      changeDestination,
      changeUploadMetadataTemplate,
      changeUploadMetadata,
      revertUploadMetadata,
      setVodUploadOptions,
      openApplication} = this.props
    let transfers = fileTransferList.map((id) => {
      return <FileTransfer {...fileTransfers[id]}
        key={id}
        id={id}
        pauseFileTransfer={pauseFileTransfer}
        resumeFileTransfer={resumeFileTransfer}
        cancelFileTransfer={cancelFileTransfer}
        clearFileTransfer={clearFileTransfer}
        restartFileTransfer={restartFileTransfer}
        editMetadata={this.handleEditMetadata}/>
    })

    return (<div id='UploadApp'>
      <UploadControls destination={destination}
        template={template}
        metadata={mergeMetadata({metadata: templateData, swapdata: metadata})}
        timezone={timezone}
        form={config.uploadForm}
        vodUploadOptions={vodUploadOptions}
        cloudPlaylists={cloudPlaylists}
        cloudPlaylistsFetchError={cloudPlaylistsFetchError}
        fetchCloudPlaylists={fetchCloudPlaylists}
        clearCloudPlaylistError={clearCloudPlaylistError}
        setVodUploadOptions={setVodUploadOptions}
        changeDestination={changeDestination}
        changeUploadMetadataTemplate={changeUploadMetadataTemplate}
        newFileTransfer={startUpload}
        changeUploadMetadata={changeUploadMetadata}
        revertUploadMetadata={revertUploadMetadata}
        fileData={fileData}
        templatePath={METADATA_TEMPLATE_PATH}
        metadataTags={metadataTags}
        prompt={this.props.prompt}
        alert={this.props.alert}
        openApplication={openApplication}/>
      <div id='FileTransferContainer'>
        <Header as='h2' content='File Transfers' icon='share' id='fileTransfersHeader'/>
        <Divider fitted/>
        <Button title="Clear all transfers" id="transfersClearAll" onClick={this.handleClearAll}>
          <Icon name="remove"/>
          Clear All
        </Button>
        <Segment.Group id='FileTransfers'>
          {transfers}
        </Segment.Group>
      </div>
    </div>)
  }

}

const mapStateToProps = (state) => ({
  fileData: state.file_list.fileData,
  metadataTags: state.file_list.metadataTags,
  timezone: state.menu.timezone,
  config: state.server.config,
  cloudPlaylists: state.cloud.cloudPlaylists,
  cloudPlaylistsFetchError: state.cloud.cloudPlaylistsFetchError,
  ...state.upload,
  vodUploadOptions: getSendToVodOptions(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...actions,
  openApplication,
  loadFileData,
  fetchCloudPlaylists: cloudActions.fetchCloudPlaylists,
  clearCloudPlaylistError: cloudActions.clearCloudPlaylistError,
  ...messages
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Upload)
