import React, {PureComponent} from 'react'
import {Segment, Header, Dropdown, Menu, Icon, Modal} from 'semantic-ui-react'
import CopyPasteSettingsModal from './CopyPasteSettingsModal'
import AccessUrlsModal from './AccessUrlsModal'
import GrabSnapshotModal from './GrabSnapshotModal'
import NDIModal from './NDIModal'
import SaveSettingsAnimation from '../Hook_Components/Animations/SaveSettingsAnimation';

export default class ServiceSettingsHeader extends PureComponent {

  handleStartService = () => {
    this.props.startService(this.props.type, this.props.id)
  }

  handleStopService = () => {
    this.props.stopService(this.props.type, this.props.id)
  }

  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  render() {
    let {id,
    type,
    settings,
    targetSchema,
    openApplication,
    saveServiceSettings,
    handleDownloadSettings,
    startOutputRecording,
    stopOutputRecording,
    renameOutputRecording,
    outputRecordingStatus} = this.props

    let name = id
    let active = settings ? settings.running : false

    let AccessModal = null
    let NDIScanButton = null
    let ControlsButton = null
    let RecordingDropdown = null

    let StartStopService = active
        ? (<Menu.Item icon='stop' content='Stop service' onClick={() => {this.handleStopService()}}/>)
        : (<Menu.Item icon='play' content='Start service' onClick={() => {this.handleStartService()}}/>)

    if(settings && targetSchema) {
      if(settings.name) {
        name = settings.name
      }
      if((type === 'output' && targetSchema.driver.const === 'internet_mpeg') ||
      type === 'input') {
        AccessModal = (<AccessUrlsModal id={id} type={type} settings={settings}/>)
      }
      if(type === 'input' && settings.interface === "ndi") {
        NDIScanButton = (<NDIModal type={type} id={id} modifySettings={this.props.modifySettings} settings={settings}/>)
      }
    }
    if(type === 'input' || type === 'router') {
      ControlsButton = (<Menu.Item icon='keyboard' content='Controls' onClick={() => {openApplication('Settings', `/${type}/${id}/controls`)}}/>)
    }
    if(type === 'output' && targetSchema && targetSchema.driver.const === 'internet_mpeg') {
      RecordingDropdown = (<Dropdown trigger={<Menu.Item content="Recording" icon="circle" fitted className="settingsMenuDropdown"/>} icon={null} item>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => {outputRecordingStatus(id)}}>
            Recording Status
          </Dropdown.Item>
          <Dropdown.Item onClick={() => {startOutputRecording(id)}}>
            Start Recording
          </Dropdown.Item>
          <Dropdown.Item onClick={() => {stopOutputRecording(id)}}>
            Stop Recording
          </Dropdown.Item>
          <Dropdown.Item onClick={() => {renameOutputRecording(id)}}>
            Rename Recording
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>)
    }

    let applySettingsAnimation = (
      <Modal
      centered={false}
      open={this.state.open}
      onClose={() => this.setState({ open: false })}
      onOpen={() => this.setState({ open: true })}
      trigger={<Menu.Item onClick={() => saveServiceSettings(type, id)} icon="save" content="Apply" />}
      style={{ height: "300px", width: "500px", position: "absolute", top: 100 }}
      >
        <Modal.Content>
          <Header size="large" textAlign="center">
            Your settings have been saved!
          </Header>
          <SaveSettingsAnimation />
        </Modal.Content>
      </Modal>
    )

    return (
      <Segment id='ServiceSettingsHeader'>
        <div style={{ display: "flex" }}>
          <Header size='large'>
            {name}
          </Header>
          <Header size="large" id="IconOffOrON">
          {/* Icon changes when a service is running */}
            <Icon
              name={active ? 'play' : 'stop'}
              color={active ? 'green' : 'red'}
            />
          {/* Text changes when a service is running */}
          {active ? "On" : "Off"}
          </Header>
        </div>
        <Menu fluid icon='labeled'>
          <Menu.Item icon='arrow left' content='Back' onClick={() => {openApplication('Settings', `/${type}`)}}/>
          {/* Apply Menu Item - Includes Animation after submission */}
          {applySettingsAnimation}
          <CopyPasteSettingsModal id={id}
            type={type}
            settings={settings}
            schema={targetSchema}
            saveServiceSettings={saveServiceSettings}/>
          <Menu.Item onClick={handleDownloadSettings} content="Download Settings" icon="download"/>
          {StartStopService}
          {AccessModal}
          {(type === 'channel' || type === 'input') ?
            <GrabSnapshotModal type={type} id={id}/> :
            ''}
          {NDIScanButton}
          {ControlsButton}
          {RecordingDropdown}
        </Menu>
      </Segment>
    )
  }

}
