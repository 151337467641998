import {SOURCE} from 'config'
import {messages} from 'redux/messages'
import {bakeCookie} from 'redux/cookies'
import {mergeMetadata, metadataList, parseMetadataTemplate} from 'helpers/metadata_helpers'
import {getIn, setIn, encodeURIFilepath} from 'helpers/general_helpers'
import {fetchFromServer, fetchFileFromServer, serverXMLRequest, serverPath} from 'helpers/net_helpers'
import {dataPath, fileTypeInfo, slashesToUnicodeSlashes} from 'helpers/library_helpers'

import loaderReducer from 'redux/higher_order_reducers/loaderReducer'
import {actions as loading} from 'redux/higher_order_reducers/loaderReducer'

export const LOAD_FILE_DATA = Symbol('load file data')
export const LOAD_FONTS = Symbol('load fonts')
export const GRAB_FILE = Symbol('grab file')
export const COPY_FILE = Symbol('copy file')
export const DELETE_FILE = Symbol('delete file')
export const SAVE_METADATA = Symbol('save metadata')
export const CLIPBOARD_METADATA = Symbol('copy metadata')
export const DOWNLOAD_START = Symbol('download start')
export const DOWNLOAD_PROGRESS = Symbol('download progress')
export const DOWNLOAD_FINISHED = Symbol('download finished')
export const DOWNLOAD_ERROR = Symbol('download error')
export const DOWNLOAD_DISMISS = Symbol('download dismiss')
export const SET_SEARCH_NEXT = Symbol('set search next')
export const SET_IS_SEARCHING = Symbol('set is searching')
export const SET_SEARCH_RESULTS = Symbol('set search results')
export const SET_GENERATING_METADATA = Symbol('set generating metadata')
export const SET_INPUT_ASSOCIATION = Symbol('set input association')

const METADATA_TO_NOT_COPY = [
  'file type',
  'guid',
  'hd',
  'sd',
  'cc'
]

var MOCK_FILE_DATA = {                 // Object containing data of all files. Each key is a file's rpath, and the value is an object containing that file's data.
  'Star': {
    size: 11546,
    type: 'folder',
    mtime: 1392848461953,
    guid: 'star-guid',
    metadata: {
      duration: '4927'
    },
    contents: {
      'Trek': {
        size: 1200,
        type: 'file',
        mtime: 1392848461953,
        guid: 'trek-guid',
        metadata: {
          'file type': 'video/mpeg',
          duration: '34'
        }
      },
      'Wars': {
        size: 8600,
        type: 'file',
        mtime: 1393885613870,
        guid: 'wars-guid',
        metadata: {
          'file type': 'video/mpeg',
          duration: '4953.124654'
        }
      },
      'Oy': {
        size: 1746,
        type: 'folder',
        mtime: 1393658063262,
        guid: 'oy-guid',
        metadata: {
          duration: '734.33333'
        },
        contents: {
          'Foo': {
            size: 546,
            type: 'file',
            date: '5/13/2005',
            guid: 'foo-guid',
            metadata: {
             'file type': 'application/x-castus-scrolling-text',
            }
          },
          'Bar': {
            size: 1200,
            type: 'file',
            date: '7/14/2021',
            guid: 'bar-guid',
            metadata: {
              duration: '30'
            }
          }
        }
      }
    }
  },
  'Moon': {
    size: 5600000,
    type: 'file',
    guid: 'moon-guid',
    metadata: {
      'file type': 'video/mpeg',
      duration: '918'
    }
  },
  'Sun': {
    size: 1400000000,
    type: 'file',
    guid: 'sun-guid',
    metadata: {
      'file type': 'star/g2v',
      'surface gravity': '27.94g',
      'absolute magnitude': '4.83',
      'surface temperature': '5778K'
    }
  }
}

const mockPath = (path) => {
  let mockPath = []
  path.forEach((level) => {
    mockPath.push(level);
    mockPath.push('contents');
  })
  return mockPath
}

const loadMockData = (path) => {
  path = mockPath(path)
  let data = getIn(MOCK_FILE_DATA, path)
  Object.keys(data).forEach((key) => {
    if(data[key].type === 'folder' && data[key].contents) {
      data = setIn(data, [key, 'contents'], undefined)
    }
  })
  if(!data) {
    data = {}
  }
  return data
}

const copyMockData = async (oldpath, newpath) => {
  oldpath = mockPath(oldpath)
  newpath = mockPath(newpath)
  if(getIn(MOCK_FILE_DATA, oldpath)) {
    MOCK_FILE_DATA = setIn(MOCK_FILE_DATA, newpath,getIn(MOCK_FILE_DATA, oldpath))
  }
}

const deleteMockData = async (rpath) => {
  rpath = mockPath(rpath).slice(0, -1)
  if(getIn(MOCK_FILE_DATA, rpath)) {
    let filename = rpath[rpath.length - 1]
    delete getIn(MOCK_FILE_DATA, rpath.slice(0, -1))[filename]
  }
}

/**
 * Asynchronously loads the file list for a given path, unless that file list is already loaded
 * @param {array} path The path to load data from
 * @param {boolean} [opts.forceLoading=false] If set to true, always bring up the loading indicator for the library.
 *  If false, the loading indicator will only show if that path has not been loaded before.
 * @param {boolean} [opts.throwNotExist=false] By default, if path does not exist, then the file data for path
 *  will be set to an empty object, and file data for the containing directory of path will be loaded instead.
 *  If this option is set to true and path does not exist, then an ENOENT error will be thrown.
 */
export const loadFileData = (path, opts={}) => {
  return async (dispatch, getState) => {
    let {forceLoading=false, throwNotExist=false} = opts
    let data = {}
    if((path instanceof Array && path.join('/') === "mnt") || path === "mnt") {
      return dispatch(loadDriveData());
    }
    if(SOURCE === 'local') {
      data = loadMockData(path)
    } else if (SOURCE === 'server') {
      let jobId;
      if(forceLoading || !getIn(getState(), ['file_list', 'fileData', ...dataPath(path, true)])) {
        jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
      }
      let checkType = await checkFileTypes([path])
      let strPath = path.join('/')
      if(!strPath.startsWith('/')) {
        strPath = `/${strPath}`
      }
      if(checkType) {
        let {type} = fileTypeInfo(checkType[strPath])
        if(type !== "folder") {
          if(type && checkType[strPath] && type !== "unknown") {
            if(jobId) {
              dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
            }
            let err = new Error(`Can only get the list of files from a folder, but the path given was not the path of a folder: ${strPath}`)
            err.code = "ERROR_NOT_A_FOLDER"
            throw err
          } else {
            if(jobId) {
              dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
            }
            let errText = `Couldn't get the list of files at the given path; the path given does not exist: ${strPath}`
            if(throwNotExist) {
              let err = new Error(errText)
              err.code = "ENOENT"
              throw err
            }
            console.error(errText)
            dispatch({
              type: LOAD_FILE_DATA,
              payload: {
                data: {},
                path: dataPath(path, true)
              }
            })
            path = path.slice(0, -1)
          }
        }
      }
      let response = await fetchFromServer(`/v2/files/list/${encodeURIFilepath(path).join('/')}`);
      if(response.ok) {
        response = await response.json()
      } else {
        response = []
      }
      if(jobId) {
        dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      }
      response.forEach((item) => {
        if(item.name) {
          data[item.name] = item
        }
      })
    }
    dispatch({
      type: LOAD_FILE_DATA,
      payload: {
        data,
        path: dataPath(path, true)
      }
    })
  }
}

export const checkFileTypes = async (paths=[]) => {
  paths = paths.map((path) => {
    if(path instanceof Array) {
      path = path.join('/')
    }
    if(!path.startsWith('/')) {
      path = `/${path}`
    }
    return path
  })
  let res = await fetchFromServer("/v2/files/identify", {
    method: "POST",
    body: paths.join("\n")
  })
  if(res.ok) {
    let types = await res.json()
    return types
  } else {
    let err = await res.text()
    console.error(`Error checking file types: ${err}`)
  }
}

/**
 * Loads the drive data to be displayed under /mnt
 */
export const loadDriveData = () => {
  return async (dispatch, getState) => {
    let data = {}
    let jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
    let response = await fetchFromServer("/v2/files/drives");
    if(response.ok) {
      let drives = await response.json()
      for(let drive of drives) {
        switch(drive.type) {
          case "Partition":
          case "Hard Drive": {
            if (!drive.mounted && (drive.filesystem === "" || drive.filesystem === " "))
              continue

            let label = drive.label || drive.dev
            let type;

            if (drive.place === 'Boot')
              type = "filesystem/boot";
            else if (drive.place === 'Main')
              type = "filesystem/main";
            else
              type = drive.mounted ? "filesystem/mounted" : "filesystem/unmounted"

            data[drive.dev] = {
              filesystem: drive.filesystem,
              guid: drive.dev,
              name: drive.dev,
              label,
              size: parseFloat(drive.capacity),
              type,
              data: drive
            }
            break;
          }
          case "CD-ROM": {
            let label = drive.label || drive.dev
            let type = drive.mounted ?
              "cdrom/mounted" :
              "cdrom/unmounted"
            data[drive.dev] = {
              filesystem: drive.filesystem,
              guid: drive.dev,
              name: drive.dev,
              label,
              size: parseFloat(drive.capacity),
              type,
              data: drive
            }
            break;
          }
          case "network share":
            let label = drive.label || drive.dev
            let type = "network/mounted"
            data[drive.dev] = {
              filesystem: drive.filesystem,
              guid: drive.dev,
              name: drive.dev,
              label,
              size: parseFloat(drive.capacity),
              type,
              data: drive
            }
            break;
          default: {
            if (!drive.mounted && (drive.filesystem === "" || drive.filesystem === " "))
              continue

            let label = drive.label || drive.dev
            let type = drive.mounted ?
              "filesystem/mounted" :
              "filesystem/unmounted"
            data[drive.dev] = {
              filesystem: drive.filesystem,
              guid: drive.dev,
              name: drive.dev,
              label,
              size: parseFloat(drive.capacity),
              type,
              data: drive
            }
            break;
          }
        }
      }
      dispatch({
        type: LOAD_FILE_DATA,
        payload: {
          data,
          path: dataPath(['mnt'], true)
        }
      })
    } else {
      let errMsg = await response.text();
      dispatch(messages.alert(`There was an error getting the drive information: ${errMsg}`, {level: "error"}))
    }
    dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
  }
}

export const ejectDrive = (dev, readOnly=false) => {
  return async (dispatch, getState) => {
    let jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
    let response = await fetchFromServer(`/v2/files/drives/eject/${dev}`, {method: "POST"});
    if(response.ok) {
      dispatch(loadDriveData());
    } else {
      let errMsg = await response.text();
      dispatch(messages.alert(`There was an error ejecting the drive: ${errMsg}`, {level: "error"}));
    }
    dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
  }
}

export const mountDrive = (dev, readOnly=false) => {
  return async (dispatch, getState) => {
    let jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
    let query = ""
    if(readOnly) {
      query = "?readOnly=1"
    }
    let response = await fetchFromServer(`/v2/files/drives/mount/${dev}${query}`, {method: "POST"});
    if(response.ok) {
      dispatch(loadDriveData());
    } else {
      let errMsg = await response.text();
      dispatch(messages.alert(`There was an error mounting the drive: ${errMsg}`, {level: "error"}));
    }
    dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
  }
}

export const unmountDrive = (dev) => {
  return async (dispatch, getState) => {
    let jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
    let response = await fetchFromServer(`/v2/files/drives/unmount/${dev}`, {method: "POST"});
    if(response.ok) {
      dispatch(loadDriveData());
    } else {
      let errMsg = await response.text();
      dispatch(messages.alert(`There was an error unmounting the drive: ${errMsg}`, {level: "error"}));
    }
    dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
  }
}

/**
 * Searches the library for file based on a given file name
 * @param {string} searchString The string file name to search by
 */
export const searchFileData = (searchString) => {
  return async (dispatch, getState) => {
    if(getState().file_list.isSearching) {
      return
    }
    dispatch(setIsSearching(true))
    let jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
    let response = await fetchFromServer(`/v2/other/search-library?name=${searchString}`)
    if(response.ok) {
      let {results} = await response.json()
      let data = {}
      let metadataFilepaths = []
      for(let item of results) {
        // for some reason, c4mi_search_library gives mtime as seconds instead of milliseconds
        if(item.mtime) {
          item.mtime = item.mtime * 1000
        }
        if(item.path && item.name) {
          let fullpath = `${item.path}/${item.name}`
          metadataFilepaths.push(fullpath)
          data[item.name] = item
        }
      }
      let metadataRes = await fetchFromServer("/v2/files/metadata-get", {
        method: "POST",
        body: metadataFilepaths.join("\n")
      })
      if(metadataRes.ok) {
        let metadata = await metadataRes.json()
        let loadFileData = []
        Object.entries(metadata).forEach(([item, mdata]) => {
          let itemName = item.split('/').pop()
          if(data[itemName]) {
            data[itemName].metadata = mdata
            loadFileData.push({data: data[itemName], path: dataPath(item.split('/').slice(1))})
          }
        })
        dispatch({
          type: LOAD_FILE_DATA,
          payload: loadFileData
        })
      }
      dispatch(setSearchResults(searchString, data))
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      dispatch(setIsSearching(false))
    } else {
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      dispatch(setIsSearching(false))
      console.error("The search failed.")
    }
  }
}

export const setSearchResults = (param, data) => ({
  type: SET_SEARCH_RESULTS,
  payload: {
    param,
    data
  }
})

export const setSearchNext = (searchString) => ({
  type: SET_SEARCH_NEXT,
  payload: searchString
})

export const setIsSearching = (isSearching) => ({
  type: SET_IS_SEARCHING,
  payload: isSearching
})

export const setInputAssociation = (inputPath, associationId) => ({
  type: SET_INPUT_ASSOCIATION,
  payload: {
    inputPath,
    associationId
  }
})

/**
 * Asynchronously loads the list of fonts and styles from the server
 */
export const loadFonts = () => {
  return async (dispatch, getState) => {
    let response = await fetchFromServer(`/v2/other/font-list`);
    if(response.ok) {
      let fonts = await response.json()
      let fontList = {}
      Object.entries(fonts).forEach(([font, properties]) => {
        let {styles} = properties
        fontList[font] = Object.keys(styles)
      })
      dispatch({
        type: LOAD_FONTS,
        payload: fontList
      })
    }
  }
}

/**
 * Downloads a file
 */
export const downloadFile = (path) => {
  return async (dispatch, getState) => {
    let {downloads} = getState().file_list
    let mtime = -1
    let statsRes = await fetchFromServer(`/v2/files/stat/${path.map(encodeURIComponent).join('/')}`, {
      headers: {"Cache-Control": "no-cache"}
    })
    if(statsRes.ok) {
      let stats = await statsRes.json()
      mtime = stats.mtimeMs
    }
    // Always redownload if we don't know when the file was last changed
    if(mtime !== -1) {
      let download = downloads.find((dl) => dl.path === path.join('/'))
      if(download) {
        // If the file was downloaded previously and has not been changed, just use the same download link instead of redownloading it
        if(download.url && download.mtime === mtime) {
          let dl_element = document.createElement('a')
          dl_element.href = download.url
          dl_element.download = path.slice(-1)[0]
          dl_element.click()
          dl_element.remove()
          return
        // If the mtime changed, or the url is no longer available, dismiss the old download so we can start a new one
        } else {
          dispatch(downloadDismiss(path))
        }
      }
    }
    let reqURL = serverPath(`/v2/files/get/${path.map(encodeURIComponent).join('/')}`)
    let req = new XMLHttpRequest()
    try {
      req.addEventListener("loadstart", () => {
        dispatch(downloadStart(path, req, mtime))
      })
      req.addEventListener("load", () => {
        let name = path.slice(-1)[0]
        let blob = req.response
        let file = new File([blob], name, {type: req.getResponseHeader('Content-Type')})
        let url = URL.createObjectURL(file)
        dispatch(downloadFinished(path, url))
        let dl_element = document.createElement('a')
        dl_element.href = url
        dl_element.download = name
        dl_element.click()
      })
      req.addEventListener("progress", (e) => {
        dispatch(downloadProgress(path, e))
      })
      req.addEventListener("error", (e) => {
        dispatch(downloadError(path, e))
      })
      req.responseType = 'blob'
      req.open("GET", reqURL)
      serverXMLRequest(req)
      req.send()
    } catch (err) {
      console.error(err)
    }
  }
}

const downloadStart = (path, request, mtime) => ({
  type: DOWNLOAD_START,
  payload: {
    path,
    request,
    mtime
  }
})

const downloadProgress = (path, e) => ({
  type: DOWNLOAD_PROGRESS,
  payload: {
    path,
    e
  }
})

const downloadFinished = (path, url) => ({
  type: DOWNLOAD_FINISHED,
  payload: {
    path,
    url
  }
})

const downloadError = (path, e) => ({
  type: DOWNLOAD_ERROR,
  payload: {
    path,
    e
  }
})

export const downloadDismiss = (path) => {
  return (dispatch, getState) => {
    let downloads = getState().file_list.downloads
    let ind = downloads.findIndex((dl) => {
      return dl.path === path.join('/')
    })
    if(ind > -1) {
      let download = downloads[ind]
      if(download.request && download.request.readyState !== 4) {
        download.request.abort()
      }
      if(download.url) {
        URL.revokeObjectURL(download.url)
      }
      downloads = downloads.filter((dl, index) => (index !== ind))
    }
    dispatch({
      type: DOWNLOAD_DISMISS,
      payload: downloads
    })
  }
}

/**
 * Downloads a file's consent form (if one exists)
 */
export const downloadConsentForm = (path) => {
  return async (dispatch, getState) => {
    let jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
    try {
      if(typeof path !== "string") {
        path = `/${path.join("/")}`
      }
      let res = await fetchFromServer(`/v2/files/consent-form/${encodeURI(path)}`)
      if(res.ok) {
        let text = await res.text()
        let filename = path.split("/").pop()
        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', `${filename}.consent_form.txt`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      } else if(res.status === 404) {
        dispatch(messages.alert("Error downloading file's consent form: file does not have a consent form.", {level: "error"}))
      } else {
        let err = await res.text()
        console.error(res)
        dispatch(messages.alert(`Error downloading file's consent form: ${res.status}: ${err}`, {level: "error"}))
      }
    } catch (err) {
      console.error(err)
      dispatch(messages.alert(`Error downloading file's consent form: ${err.message}`, {level: "error"}))
    }
    dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
  }
}

/**
 * Uploads one file to the server. Goes through file transfers
 * @param {File/Blob} file The file to be uploaded
 * @param {string} dest The location to upload the file to
 * @param {object} options Optional arguments
 * @param {function} options.onProgress A callback function that takes a progress event as an argument, called on transfer progress
 * @param {function} options.onLoad A callback function that takes a load event as an argument, called on upload completing successfully
 */
export const uploadFile = (file, dest, options={}) => {
  return async (dispatch, getState) => {
    let {id, abortSignal} = options
    let formDat = new FormData();
    // send the file size FIRST, then the file.
    // The reason this matters is that Content-Length will only provide the total size of the entire multipart form data,
    // not the actual size of the file. The server-side control-api wants to know ahead of time the size of the file so
    // it can correctly know if the entire file made it or not.
    formDat.append('size', file.size);
    formDat.append('file', file, file.name);
    try {
      let response = await fetchFromServer(`/v2/uploads/upload${id ? `?id=${id}` : ''}`, {
        method: 'PUT',
        body: formDat,
        signal: abortSignal
      })
      if(response.ok) {
        dispatch(loadFileData(dest.split('/'), {forceLoading: true}))
      } else {
        let err = response.text()
        dispatch(messages.alert(`There was an error uploading the file: ${err}`, {level: "error"}))
        //window.alert("Something went wrong")
      }
    } catch (err) {
      if(!err.message.startsWith("The operation was aborted.")) {
        console.error(`'${err.message}'`)
        dispatch(messages.alert(`There was an error uploading the file: ${err.message}`, {level: "error"}))
      }
    }
  }
}

/**
 * Saves a file to the server, saving directly to disk
 * @param {File/Blob} file The file to be uploaded
 * @param {string} dest The location to upload the file to
 */
export const saveFile = (file, dest, opts) => {
  return async (dispatch, getState) => {
    let {createMetadata, onError, onSuccess} = opts
    let path = encodeURIComponent(dest)
    let formDat = new FormData();
    formDat.append('file', file, file.name);
    let metadata = createMetadata ? '&generate_metadata=1' : ''
    let response = await fetchFromServer(`/v2/files/update?path=${path}${metadata}`, {
      method: 'PUT',
      body: formDat,
    })
    if(response.ok) {
      dispatch(loadFileData(dest.split('/'), {forceLoading: true}))
      if(onSuccess) {
        onSuccess(response)
      }
    } else {
      if(onError) {
        onError(response)
      }
    }
  }
}

/**
 * Saves changes to metadata for a file
 * @param {object} metadata Metadata changes to be saved. A non-null value for a key indicates a metadata value to be updated.
 *  A null value for a key indicates that that metadata key should be deleted if it exists.
 * @param {array/string} filepath Absolute path of the file to update metadata for
 */
export const saveMetadata = (metadata, filepath) => {
  console.log(`/v2/other/metadata-editor-save/${encodeURIFilepath(filepath).join('/')}`)

  return async (dispatch, getState) => {
    let saveData = JSON.stringify(metadata)
    let res = await fetchFromServer(`/v2/other/metadata-editor-save/${encodeURIFilepath(filepath).join('/')}`, {
      method: 'POST',
      body: saveData,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if(!res.ok) {
      let err = await res.text();
      dispatch.global(messages.alert(`Error updating metadata for file /${filepath}: ${err}`, {level: 'error'}))
    } else {
      if(typeof filepath === "string") {
        filepath = filepath.split('/')
      }
      await dispatch(loadFileData(filepath.slice(0, -1)))
    }
  }
}

export const generateMetadata = (path) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_GENERATING_METADATA,
      payload: {
        path,
        val: true
      }
    })
    let response = await fetchFromServer(`/v2/files/generate-meta/${encodeURIFilepath(path).join('/')}`, {
      method: 'POST'
    });
    if(response.ok) {
      dispatch(loadFileData(path.slice(0, -1), {forceLoading: true}))
    }
    dispatch({
      type: SET_GENERATING_METADATA,
      payload: {
        path,
        val: false
      }
    })
  }
}

/**
 * Grabs one or more files into the file clipboard (overwriting the current clipboard), and sets the paste mode
 * @param {string} mode What operation to perform when pasting, either "move" or "copy"
 * @param {array} files An array of file rpaths to place into the clipboard
 */
export const grabFiles = (mode, files) => (
  {
    type: GRAB_FILE,
    payload: {
      mode,
      files
    }
  }
)

/**
 * Asynchronously creates a new directory in the file system
 * @param {array} path The path to create the directory in
 * @param {string} name The name to give the directory, which defaults to NEW_DIRECTORY
 */
export const createFolder = (path, name='NEW_DIRECTORY') => {
  return async (dispatch, getState) => {
    let fullpath = [...path, slashesToUnicodeSlashes(name)]
    let jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
    let response = await fetchFromServer(`/v2/files/create-folder/${encodeURIFilepath(fullpath).join('/')}`, {method: 'POST'})
    if(response.ok) {
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      dispatch(loadFileData(path), {forceLoading: true})
    } else {
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      let err = await response.text()
      if(err) {
        dispatch(messages.alert(`There was an error creating the directory: ${err}`, {level: "error"}))
      } else {
        dispatch(messages.alert(`There was an unknown error creating the directory!`, {level: "error"}))
      }
    }
  }
}

/**
 * Copies file in the filesystem.
 * @param {array} files An array of copy operations to perform. Each operation is itself an array containing two more arrays:
 *  the first array being the path of the source file and the second array being the destination to copy the source to.
 */
export const copyFiles = (files) => {
  return async (dispatch, getState) => {
    let jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
    let copyOperations = Promise.all(files.map(async (op) => {
      let [oldpath, newpath] = op
      if(SOURCE === 'local') {
        return copyMockData(oldpath, newpath)
      }
      let response = await fetchFromServer(`/v2/files/copy?from=/${encodeURIFilepath(oldpath).join('/')}&to=/${encodeURIFilepath(newpath).join('/')}`, {method: 'POST'})
      if(response.ok) {
        return
      } else {
        let err = await response.text()
        if(!err) {
          err = "No error text was received from the server."
        }
        throw new Error(err);
      }
    }))
    try {
      await copyOperations;
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      dispatch(messages.alert(`${files.length} files are now being copied. You may view the copy operation status in the File Transfers section of the Uploads page.`, {level: "success"}))
      return true
    } catch (err) {
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      dispatch(messages.alert(`There was an error copying the file: ${err}`, {level: "error"}))
      return false
    }
    /*
    return dispatch({
      type: COPY_FILE,
      payload: {
        oldpath,
        newpath
      }
    })
    */
  }
}

/**
 * Sets the file to copy metadata from
 * @param {array} path The path of the file to copy metadata from
 */
export const clipboardMetadata = (path) => ({
  type: CLIPBOARD_METADATA,
  payload: path
})

/**
 * Copies the metadata of one file to another file
 * @param {array} targetPath The path of the file that metadata is being copied to
 * @param {array} srcPath The path of the file that serves as the source of the metadata.
 *  Defaults to using the file path that is currently in the metadata clipboard. If no
 *  file path is in the metadata clipboard, then the operation will be aborted
 */
export const copyMetadata = (targetPath, srcPath=null) => {
  return async (dispatch, getState) => {
    let {fileData, metadataClipboard} = getState().file_list
    if(srcPath === null) {
      srcPath = metadataClipboard
    }
    if(!srcPath || srcPath.length === 0) {
      return
    }
    let metadata = getIn(fileData, [...dataPath(srcPath), 'metadata'])
    if(!metadata) {
      return
    }
    let jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
    // Special case for metadata templates; get the metadata stored in the template itself rather than
    //  the template file's metadata
    if(metadata["file type"] === "application/x-castus-metadata-template") {
      let res = await fetchFileFromServer(srcPath.join("/"))
      if(!res.ok) {
        let err = await res.text()
        dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
        dispatch(messages.alert(`There was an error getting the metadata from template /${srcPath.join('/')}: ${err}`, {level: 'error'}))
        return
      }
      let data = await res.text()
      try {
        metadata = parseMetadataTemplate(data)
      } catch (err) {
        dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
        if(err.type === 'NOT_METADATA_TEMPLATE') {
          dispatch.global(messages.alert(`Tried to copy metadata from template /${srcPath.join('/')}, but that file is not a valid metadata template.`, {level: 'error'}))
          return
        } else {
          throw err
        }
      }
    }

    let baseData = {}
    let deleteData = {}
    let originalData = getIn(fileData, [...dataPath(targetPath), 'metadata'])

    let DoNotCopy = [ ]
    for(let x of METADATA_TO_NOT_COPY) DoNotCopy.push(x)

    let isImage = false
    if ("file type" in originalData) {
      if (originalData["file type"].substr(0,6) === "image/") {
        isImage = true
      }
    }

    if (!isImage)
      DoNotCopy.push("duration")

    for(let tag of DoNotCopy) {
      if(tag in metadata) {
        delete metadata[tag]
      }
    }

    if(originalData) {
      Object.entries(originalData).forEach(([key, value]) => {
        if(DoNotCopy.includes(key)) {
          baseData[key] = value
        } else {
          deleteData[key] = null
        }
      })
    }
    let toSave = {...deleteData, ...mergeMetadata(baseData, metadata)}
    toSave = JSON.stringify(toSave)
    let res = await fetchFromServer(`/v2/other/metadata-editor-save/${encodeURIFilepath(targetPath).join('/')}`, {
      method: 'POST',
      body: toSave,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if(!res.ok) {
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      let err = await res.text()
      if(err) {
        dispatch(messages.alert(`There was an error copying the metadata: ${err}`, {level: "error"}))
      } else {
        dispatch(messages.alert(`There was an unknown error copying the metadata!`, {level: "error"}))
      }
    } else {
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      dispatch(loadFileData(targetPath.slice(0, -1), {forceLoading: true}))
    }
  }
}

/**
 * Deletes multiple files from the filesystem. Asks user for confirmation first.
 * @param {array} paths An array of path arrays to delete
 */
export const confirmThenDeleteFiles = (paths) => {
  return async (dispatch, getState) => {
    if(!(await dispatch(messages.confirmAsync('Are you sure you want to delete these files?')))) {
      return
    }
    return await Promise.all(paths.map(path => dispatch(deleteFile(path))))
  }
}

/**
 * Deletes a file from the filesystem
 * @param {array} rpath The path of the file to delete
 */
export const deleteFile = (rpath) => {
  return async (dispatch, getState) => {
    if(SOURCE === 'local') {
      await deleteMockData(rpath)
    }
    let jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
    let response = await fetchFromServer(`/v2/files/delete/${encodeURIFilepath(rpath).join('/')}`, {
      method: 'POST'
    })
    if(response.ok) {
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      dispatch(loadFileData(rpath.slice(0, -1), {forceLoading: true}))
    } else {
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      let err = await response.text()
      if(err) {
        dispatch(messages.alert(`There was an error deleting the file: ${err}`, {level: "error"}))
      } else {
        dispatch(messages.alert(`There was an unknown error deleting the file!`, {level: "error"}))
      }
      return
    }
  }
}

/**
 * Action creator for moving files.
 * @param {array} files An array of move operations to perform. Each operation is itself an array containing two more arrays:
 *  the first array being the path of the source file and the second array being the destination to move the source to.
 */
export const moveFiles = (files, opts={}) => {
  return async (dispatch, getState) => {
    let jobId = dispatch(loading.startLoading(getIn(getState(), ['file_list', '_loaderID'])));
    let moveOperations = Promise.all(files.map(async (op) => {
      let [oldpath, newpath] = op
      let response = await fetchFromServer(`/v2/files/move?from=/${encodeURIFilepath(oldpath).join('/')}&to=/${encodeURIFilepath(newpath).join('/')}`, {method: 'POST'})
      if(response.ok) {
        dispatch(loadFileData(newpath.slice(0, -1), {forceLoading: true}))
        return
      } else {
        let err = await response.text()
        if(!err) {
          err = "No error text was received from the server."
        }
        throw new Error(err);
      }
    }))
    try {
      await moveOperations;
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      return true
    } catch (err) {
      dispatch(loading.finishLoading(getIn(getState(), ['file_list', '_loaderID']), jobId))
      dispatch(messages.alert(`There was an error moving the file: ${err}`, {level: "error"}))
      return false
    }
  }
}

/**
 * Helper action creator for renaming files. Invokes moveFile.
 * @param {array} rpath The path of the file to rename
 * @param {string} newName The new name of the file
 */
export const renameFile = (rpath, newName) => {
  return async (dispatch, getState) => {
    let newPath = rpath.slice(0, -1).concat(slashesToUnicodeSlashes(newName))
    await dispatch(moveFiles([[rpath, newPath]]))
  }
}

export const sendToVod = (path, filename, opts={}) => {
  return async (dispatch, getState) => {
    let {vodConfig} = getState().cloud
    let {oldVodOk} = getState().cookies
    if(!oldVodOk && (!vodConfig || !vodConfig.email)) {
      let go = await dispatch(messages.confirmAsync("Configuration for Cloud VOD is not set up, so this file will attempt to be pushed to the old vod. If you have a Cloud VOD and are intending to push this file to it, please ensure that your Cloud VOD configuration is set up in the Cloud Services page reachable through the main menu.\nSend to old vod? (If you confirm to send to the old vod, this message will not be displayed again for this session)."))
      if(!go) {
        return
      }
      dispatch(bakeCookie("oldVodOk", true))
    }
    let uri = `/v2/uploads/vod-ctl?path=/${encodeURIFilepath(path).join('/')}&command=start`
    if(opts.playlists) {
      uri = uri + `&add_to_playlists=${opts.playlists.join(",")}`
    }
    if("caption" in opts && opts.caption !== null) {
      uri = uri + `&caption_file=${opts.caption ? "true" : "false"}`
    }
    let res = await fetchFromServer(uri, {
      method: 'POST',
      body: filename
    })
    if(res.ok) {
      return
    } else {
      let err = await res.text()
      dispatch(messages.alert(`There was an error initiating the vod file transfer: ${err}.
        (Make sure that the "Video on demand transcode service" under the "System Services" tab of the Services app is running.)`, {level: "error"}))
    }
  }
}

export const libraryActions = {
  loadFileData,
  generateMetadata,
  downloadFile,
  grabFiles,
  confirmThenDeleteFiles,
  copyFiles,
  moveFiles,
  renameFile,
  createFolder,
  clipboardMetadata,
  copyMetadata,
  setSearchNext,
  setInputAssociation,
  sendToVod,
  ejectDrive,
  mountDrive,
  unmountDrive,
  saveMetadata,
  downloadConsentForm
}

const initialState = {
  fileData: null,               // Object containing data of all files. Each key is a file's rpath, and the value is an object containing that file's data.
  clipboard: {                // Clipboard for copy/cut and pasting files
    files: [],                // Files to be acted on
    mode: ''                  // What to do with the files (either "move" or "copy")
  },
  downloads: [                // List of ongoing file downloads
  ],
  fonts: {},                  // Object containing names of available fonts as keys and arrays of available styles as values
  metadataTags: metadataList, // Object containing all metadata tags as keys and a description of that tag as values
  metadataClipboard: [],      // Array path of the file to copy metadata from when pasting metadata
  searchNext: null,           // The param to search by on the next search
  isSearching: false,         // Whether a search is currently being performed or not
}

const reducer = (state=initialState, action) => {
  let {type, payload} = action

  switch(type) {
    case LOAD_FILE_DATA: {
      if(payload instanceof Array) {
        let fileData = state.fileData
        for(let {data, path} of payload) {
          fileData = setIn(fileData, path, data)
        }
        return {
          ...state,
          fileData
        }
      } else {
        let {data, path} = payload
        return {
          ...state,
          fileData: setIn(state.fileData, path, data)
        }
      }
    }
    case LOAD_FONTS: {
      return {
        ...state,
        fonts: payload
      }
    }
    case GRAB_FILE:
      return {
        ...state,
        clipboard: payload
      }
    case DELETE_FILE:
      let folderPath = dataPath(payload.slice(0, -1), true)
      let fileName = payload.pop()
      let toReturn = state.fileData
      let deleteFrom = getIn(toReturn, folderPath)
      if(deleteFrom && deleteFrom[fileName]) {
        delete deleteFrom[fileName]
        toReturn = setIn(toReturn, folderPath, deleteFrom)
      }
      return {
        ...state,
        fileData: toReturn
      }
    case COPY_FILE: {
      let {oldpath, newpath} = payload
      oldpath = dataPath(oldpath)
      newpath = dataPath(newpath)
      if(!getIn(state.fileData, dataPath(oldpath))) {
        return state
      }
      if(newpath.length === 0 || newpath[newpath.length - 1] === 'contents') {
        newpath.push(oldpath[oldpath.length - 1])
      }
      let toReturn = setIn(state.fileData, newpath, getIn(state.fileData, dataPath(oldpath)))
      return {
        ...state,
        fileData: toReturn
      }
    }
    case SAVE_METADATA:
      let {rpath, metadata} = payload
      return {
        ...state,
        fileData: {
          ...state.fileData,
          [rpath]: {
            ...state.fileData[rpath],
            metadata
          }
        }
      }
    case DOWNLOAD_START: {
      let {path, request, mtime} = payload
      return {
        ...state,
        downloads: [
          ...state.downloads,
          {
            state: "ongoing",
            path: path.join("/"),
            request,
            mtime
          }
        ]
      }
    }
    case DOWNLOAD_PROGRESS: {
      let {path, e} = payload
      let {lengthComputable, loaded, total} = e
      let downloads = state.downloads
      let ind = downloads.findIndex((dl) => {
        return dl.path === path.join('/')
      })
      if(ind > -1) {
        downloads = setIn(downloads, [ind], {
          ...downloads[ind],
          loaded,
          total,
          lengthComputable
        })
      }
      return {
        ...state,
        downloads
      }
    }
    case DOWNLOAD_FINISHED: {
      let downloads = state.downloads
      let {path, url} = payload
      let ind = downloads.findIndex((dl) => {
        return dl.path === path.join('/')
      })
      if(ind > -1) {
        downloads = setIn(downloads, [ind], {
          ...getIn(downloads, [ind]),
          state: "finished",
          url
        })
      }
      return {
        ...state,
        downloads
      }
    }
    case DOWNLOAD_ERROR: {
      let {path, e} = payload
      let downloads = state.downloads
      let ind = downloads.findIndex((dl) => {
        return dl.path === path.join('/')
      })
      if(ind > -1) {
        downloads = setIn(downloads, [ind], {
          ...downloads[ind],
          state: "error",
          error: e.type
        })
      }
      return {
        ...state,
        downloads
      }
    }
    case DOWNLOAD_DISMISS: {
      return {
        ...state,
        downloads: payload
      }
    }
    case CLIPBOARD_METADATA: {
      return {
        ...state,
        metadataClipboard: payload
      }
    }
    case SET_SEARCH_NEXT: {
      return {
        ...state,
        searchNext: payload
      }
    }
    case SET_IS_SEARCHING: {
      return {
        ...state,
        isSearching: payload
      }
    }
    case SET_SEARCH_RESULTS: {
      let {param, data} = payload
      return {
        ...state,
        fileData: setIn(state.fileData, ['search', param], data)
      }
    }
    case SET_GENERATING_METADATA: {
      let {path, val} = payload
      let fpath = dataPath(path)
      return {
        ...state,
        fileData: setIn(state.fileData, [...fpath, "generatingMetadata"], val)
      }
    }
    case SET_INPUT_ASSOCIATION: {
      let {inputPath, associationId} = payload
      let fpath = dataPath(inputPath)
      return {
        ...state,
        fileData: setIn(state.fileData, [...fpath, "selectedAssociation"], associationId)
      }
    }
    default:
      return state
  }
}

export default loaderReducer(reducer, initialState)
