import React, {Component} from 'react'
import {Segment,
  Input,
  Checkbox,
  Label,
  Dropdown} from 'semantic-ui-react'

import {camelToCapitalized} from 'helpers/general_helpers'
import {typeDefault, matchesSearchString} from 'helpers/settings_helpers'

import ButtonOrList from '../Hook_Components/Settings/ButtonOrList';

import './SettingPanel.css'
import ItemSettingHandler from '../Hook_Components/Settings/ItemSettingHandler';
import TranslationsInput from "./TranslationsInput"

export default class SettingPanel extends Component {

  shouldComponentUpdate(prevprops) {
    if(Object.keys(this.props).length !== Object.keys(prevprops).length) {
      return true
    }
    for(let [key, val] of Object.entries(this.props)) {
      if(key === "address") {
        let aAddress = (val || []).join(",")
        let bAddress = (prevprops[key] || []).join(",")
        if(aAddress !== bAddress) {
          return true
        }
      } else if(val !== prevprops[key]) {
        return true
      }
    }
    return false
  }

  componentDidMount() {
    this.checkValue()
  }

  componentDidUpdate(prevprops) {
    if(this.props.value !== prevprops.value) {
      this.checkValue()
    }
  }

  checkValue = () => {
    let {schema, value, setting} = this.props
    let [type, canBeNull] = parseTypeArray(schema.type)
    value = value !== undefined ? value : schema.default
    if(!canBeNull && value === null) {
      console.warn(`Received null for value with key ${setting} and type ${type}, even though null is not allowed for that setting`)
    }
    if(value && (type === "number" || type === "integer")) {
      let test = /-?(\d+|\d+\.\d+|\.\d+)([eE][-+]?\d+)?/.test(value)
      if(!test) {
        console.warn(`Expected a number for the setting ${setting}, but got the following non-numeric value: ${value}`)
      }
    }
  }

  handleChange = (e, data) => {
    let {service, serviceType, address, modifySettings, schema} = this.props
    let value = data.type === 'checkbox' ? data.checked : data.value
    if(data.type === 'number' || data.type === 'integer') {
      if(typeof value === 'string') {
        value = parseFloat(value)
      }
      if(data.type === 'integer') {
        value = Math.floor(value)
      }
      if(schema.maximum) {
        value = Math.min(value, schema.maximum)
      }
      if(schema.minimum) {
        value = Math.max(value, schema.minimum)
      }
    }
    if(data.className === "PathParam") {
      value = value.split('/')
    } else if(data.className === "ObjectParamCount") {
      value = this.handleObjectCount(value)
    }
    modifySettings(serviceType, service, address, value)
  }

  buttonOrListChange = (e, data) => {
    return this.handleChange(e, {value: data.value})
  }

  handleObjectCount = (count) => {
    let {value, schema} = this.props
    let currentValue = value !== undefined ? value : schema.default
    if(typeof count === 'string') {
      count = parseInt(count, 10)
    }
    if(!(currentValue instanceof Array)) {
      currentValue = []
      for(let i = 0; i < count; i++) {
        currentValue.push({})
      }
    } else if(count > currentValue.length) {
      let toAdd = []
      while(count > (currentValue.length + toAdd.length)) {
        toAdd.push({})
      }
      currentValue = currentValue.concat(toAdd)
    } else if(count < currentValue.length) {
      currentValue = currentValue.slice(0, count)
    }
    return currentValue
  }

  render() {
    let {setting,
      value,
      schema,
      service,
      serviceType,
      modifySettings,
      address,
      settingsSearchString,
      children,
      settingListView} = this.props

    // Creating custom component for the setting called "Item" becasue we want dynamically load that setting
    // with a dropdown that shows the current channels / inputs we can choose from
    if ((schema.display_name === "Item" && schema.description === "Channel or input path to feed from")
        || schema.description === "Channel path to feed from") {
      let props = schema
      props._options = {modifySettings, serviceType, service, address}
      return (
        <ItemSettingHandler 
          {...props} 
          onChange={ (e,data)=>this.handleChange( e, { value: data.value }) } 
          value={value}
        />
      )
    }

    // TODO: This is a hardcode for live caption translations languages for now. Add to schema as well
    if(address[0] === "live caption translation languages") {
      schema = {...schema}
      schema.advanced = false
      schema.type = "languages"
      schema.display_name = "Live Caption Translation Languages"
      schema.description = "Languages to translate live captions to (Live captions are always generated in English; selected languages are in addition to English)."
    }

    // Renders settings that are basic
    // settingListView is coming from redux store... This gets updated when a toggle settings button is pressed
    if (schema.hidden || (schema.advanced && settingListView === true)) {
      return null
    }

    if(settingsSearchString && !matchesSearchString(address.join(', '), schema, settingsSearchString)) {
      return null
    }

    let currentValue = value !== undefined ? value : schema.default

    let contents = undefined

    // schema.ui and schema.ui.presentation needs to be a property in order to render ButtonOrList component.
    if (schema.ui && schema.ui.presentation) {
      let props = schema
      /**
       * Pass down the parameters needed to put the value into the redux store
       * Put these values into a hidden option.  If I construct another object and deconstruct it
       * the name of `Enable Concurrent Init` vanishes.
       */
      //props._options = {modifySettings, serviceType, service, address}

      // ButtonOrList component handles settings that have a ui.presentation prop of 'radio buttons' or 'list'
      // Component also creates dropdowns for type number which is a custom input dropdown
      contents = <ButtonOrList
        {...props}
        modifySettings={modifySettings}
        serviceType={serviceType}
        service={service}
        address={address}
        onChange={this.buttonOrListChange}
        value={value}
      />
    } else {
      contents = getInputByType(
        schema.type,
        schema,
        camelToCapitalized(setting),
        currentValue,
        this.handleChange,
        address,
        {service, serviceType, modifySettings}
      )
    }

    let description = schema.description ? <div className='Setting Description'>{schema.description}</div> : null
    return (
      <Segment key={setting}>
        {contents}{children}{description}
      </Segment>
    )
  }
}

// Handles different settings types and renders checkbox, input, and dropdowns based off of the types
const getInputByType = (baseType, schema, label, value, onChange, address, props) => {
  let [type, canBeNull] = parseTypeArray(baseType)
  if(value === null) {
    value = typeDefault(type)
  }
  // schema.enum is a property that holds the options for a list/dropdown
  if(schema.enum) {
    // assembleDropdownInput creates the dropdown menu
    return assembleDropdownInput(type, schema, label, value, onChange, canBeNull)
  }
  if(schema.readOnly) {
    onChange = undefined
  }
  let inputComponent = null
  let datalistComponent = null
  let listName;
  if(schema.datalist) {
    let options = schema.datalist.map((opt) => {
      if(typeof opt === "object") {
        return (<option key={`${opt.value}`} value={opt.value}>{opt.name}</option>)
      } else {
        return (<option key={`${opt}`} value={opt}/>)
      }
    })
    listName = `${address.join('-')}-datalist`
    datalistComponent = <datalist id={listName}>
      {options}
    </datalist>
  }
  switch(type) {
      case 'integer':
        if(value) {
          let test = /-?(\d+|\d+\.\d+|\.\d+)([eE][-+]?\d+)?/.test(value)
          if(!test) {
            value = ''
          }
        }
        inputComponent = (<Input fluid
          type='number'
          placeholder={schema.default || ""}
          list={listName}
          disabled={schema.status}
          min={schema.minimum}
          max={schema.maximum}
          step={1}
          label={schema.display_name || label}
          value={value}
          onChange={onChange}
          readOnly={schema.readOnly}/>)
        break
      case 'number':
        if(value) {
          let test = /-?(\d+|\d+\.\d+|\.\d+)([eE][-+]?\d+)?/.test(value)
          if(!test) {
            value = ''
          }
        }
        inputComponent = (<Input fluid
          type='number'
          placeholder={schema.default || ""}
          disabled={schema.status}
          list={listName}
          min={schema.minimum}
          max={schema.maximum}
          label={schema.display_name || label}
          value={value}
          onChange={onChange}
          readOnly={schema.readOnly}/>)
        break
      case 'string':
        inputComponent = (<Input fluid
          placeholder={schema.default || ""}
          list={listName}
          label={schema.display_name || label}
          disabled={schema.status}
          value={value}
          onChange={onChange}
          readOnly={schema.readOnly}/>)
        break
      case 'boolean':
        if(typeof value === 'string') {
          if(value === "-1") {
            console.warn(`Warning: ${schema.display_name || label} is set as a boolean in the schema, but has a value of -1. Thus, it is not actually a boolean, and the schema should be changed.`)
          }
          value = (value !== 'false' && value !== '0')
        }
        inputComponent = (
          <div>
            <Label className="SettingCheckboxLabel noselect" size="large">{schema.display_name || label}</Label>
            <Checkbox checked={value}
              toggle
              disabled={schema.status}
              onChange={onChange}
              readOnly={schema.readOnly}/>
          </div>
        )
        break
      case 'path':
        if(value && value instanceof Array) {
          value = value.join("/")
        }
        inputComponent = (
          <Input fluid
            list={listName}
            label={schema.display_name || label}
            disabled={schema.status}
            value={value}
            className="PathParam"
            onChange={onChange}
            readOnly={schema.readOnly}/>
        )
        break
      case 'languages':
        inputComponent = (
          <TranslationsInput fluid
            label={schema.display_name || label}
            value={value}
            onChange={onChange}/>
        )
        break
      default:
        if(value === null && canBeNull) {
          value = ''
        }
        inputComponent = (<Input fluid list={listName} label={schema.display_name || label} value={value} onChange={onChange} readOnly={schema.readOnly}/>)
    }
    return (
      <>
        {inputComponent}
        {datalistComponent}
      </>
    )
}

const assembleDropdownInput = (type, schema, label, value, onChange, canBeNull=false) => {
  let options = schema.enum.map((option) => {
    return {text: camelToCapitalized(option), value: option}
  })
  if(canBeNull) {
    options.unshift({text: "Auto", value: 'null'})
  }
  let placeholder = "Auto"
  if(schema.default) {
    placeholder = `${schema.default}`
  }
  return (
    <div className='SettingsDropdown'>
      <Label size="large">{label}</Label>
      <Dropdown fluid value={value} onChange={onChange} selection options={options} placeholder={placeholder}/>
    </div>
  )
}

const parseTypeArray = (type) => {
  let canBeNull = false
  if(type instanceof Array) {
    let baseType = ''
    type.forEach((option) => {
      if(option === 'null') {
        canBeNull = true
      } else if (baseType === '') {
        baseType = option
      } else {
        baseType = 'string'
      }
    })
    type = baseType;
  }
  return [type, canBeNull]
}
