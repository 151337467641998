import React, {PureComponent} from 'react'
import {Button, Table, Input, Checkbox, Select} from 'semantic-ui-react'
import {capitalizeMetadata} from 'helpers/metadata_helpers'
import moment from 'moment'
import DurationInput from 'components/TimeInput/DurationInput'
import DateTimeInput from 'components/TimeInput/DateTimeInput'
import PropTypes from 'prop-types'
import {validateURL} from 'helpers/general_helpers'

class MetadataEntry extends PureComponent {

  handleChange = (e, data) => {
    let {name, onChange, metadataTag} = this.props
    if(metadataTag && metadataTag.type === "boolean") {
      onChange(name, `${data.checked}`)
    } else if (metadataTag && metadataTag.type === "url") {
      onChange(name, data.value)
    } else if (e && e.target && e.target.checkValidity) {
      if(e.target.checkValidity()) {
        if(name === "inner crop") {
          if(parseFloat(data.value) === 0) {
            data.value = "0 (zoom out)"
          }
          if(parseFloat(data.value) === 100) {
            data.value = "100 (zoom in)"
          }
        }
        onChange(name, data.value)
      }
    } else if (metadataTag && metadataTag.type === "duration") {
      let value = data.value / 1000
      onChange(name, value)
    } else {
      onChange(name, data.value)
    }
  }

  handleDelete = () => {
    let {name, onChange} = this.props
    onChange(name, null)
  }

  handleChangeDateTime = (val) => {
    this.handleChange(null, {value: val})
  }

  render() {
    let {name, value, metadataTag} = this.props
    let displayName = metadataTag ? metadataTag.display : name
    displayName = capitalizeMetadata(displayName)
    let description = metadataTag ?
      metadataTag.description :
      'A custom metadata tag'

    let metadataInput = ''
    let type = metadataTag ? metadataTag.type : ''
    switch(type) {
      case "boolean":
        metadataInput = (
          <Checkbox toggle
            checked={value === "true"}
            onChange={this.handleChange}
            label={
              <label>
                {value === "true" ? "Yes" : "No"}
              </label>
            }/>
        )
        break;
      case "select":
        metadataInput = (
          <Select value={value}
            onChange={this.handleChange}
            fluid
            options={metadataTag.options}/>
        )
        break;
      case "number":
        if(typeof value === 'string') {
          if(value === '') {
            value = 0
          } else {
            value = parseInt(value, 10)
          }
        }
        let slider = null
        if((metadataTag.min || metadataTag.min === 0) &&
          metadataTag.max) {
          slider = (
            <Input fluid
              type="range"
              min={metadataTag.min}
              max={metadataTag.max}
              step={metadataTag.step || 5}
              size="mini"
              value={value}
              onChange={this.handleChange}/>
          )
        }
        metadataInput = (
          <>
          <Input fluid
            type="number"
            value={value}
            onChange={this.handleChange}
            min={metadataTag.min}
            max={metadataTag.max}/>
          {slider}
          </>
        )
        break;
      case "list":
        let options = metadataTag.options.map((option) => (
          <option {...option}/>
        ))
        metadataInput = (
          <>
            <Input fluid
              value={value}
              onChange={this.handleChange}
              list={`${name}-list`}/>
            <datalist id={`${name}-list`}>
              {options}
            </datalist>
          </>
        )
        break;
      case "date":
        if(moment.isMoment(value)) {
          value.local()
          value = value.toDate()
        }
        metadataInput = (
          <DateTimeInput value={value} onChange={this.handleChangeDateTime}/>
        )
        break;
      case "duration":
        let defaultValue = value ? parseFloat(value) * 1000 : null
        // handleChangeDateTime used here too to avoid code duplication
        metadataInput = (
          <DurationInput fluid
            showMilliseconds="1"
            defaultValue={defaultValue}
            onChange={this.handleChangeDateTime}/>
        )
        break;
      case "url":
        let valid = true
        if(value) {
          valid = validateURL(value)
        }
        let icon, iconPosition
        if(!valid) {
          icon = {name: "warning sign", title: "This is not a valid url.", color: "red"}
          iconPosition = "left"
        }
        metadataInput = (
          <Input fluid
            type="url"
            error={!valid}
            icon={icon}
            iconPosition={iconPosition}
            value={value}
            onChange={this.handleChange}/>
        )
        break;
      default:
        metadataInput = (
          <Input fluid
            value={value}
            onChange={this.handleChange}/>
        )
        break;
    }

    return (
        <Table.Row>
          <Table.Cell width='3' className='metadataColumn'>
            <div className='metadataName'>{displayName}</div>
            <div className='metadataDescription'>{description}</div>
          </Table.Cell>
          <Table.Cell width='11' textAlign='center'>
            {metadataInput}
          </Table.Cell>
          <Table.Cell width='1'>
            <Button icon='remove'
              title='Delete Field'
              onClick={this.handleDelete}
              floated="right"/>
          </Table.Cell>
        </Table.Row>
      )
  }

}

MetadataEntry.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  metadataTag: PropTypes.object,
  timezone: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default MetadataEntry
